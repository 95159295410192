import React from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import style from "./Pricing.module.css";
import Collapse from "../../components/Collapse/Collapse";
import { MdDone } from "react-icons/md";
import Footer from "../../components/Footer/Footer";
import Slider from "../../components/Slider/Slider";
import axios from "axios";
import useScreenSize from "../../utils/Hooks/useScreenSize";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context";
import PlanSquare from "./PlanSquare/PlanSquare";

const Pricing = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const handleSubscribe = async () => {
    navigate("/createAccount");
    /* axios
      .post(
        "https://vps-3348494-x.dattaweb.com:5455/create-checkout-session",
        {
          lookup_key: "color_match",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
      }); */
  };

  const paragraphs = [
    {
      title: "Inspiration Upload",
      text: "Upload images to instantly generate matching balloon color palettes.",
    },
    {
      title: "Customizable Palettes",
      text: "Manually add, remove, or replace items in your palette to fit your design needs.",
    },
    {
      title: "Sourcing Details",
      text: "View detailed sourcing information including brands and available sizes.",
    },
    {
      title: "Client Sharing",
      text: "Easily export and share your custom balloon color palettes with clients.",
    },
    {
      title: "Alternative Brands",
      text: "Find and compare alternative balloon brands and sizes for your designs.",
    },
    {
      title: "Accessory Database",
      text: "Access a comprehensive database of popular confetti, tassel, and foil accessories for your balloon designs.",
    },
    {
      title: "Time-Saving Tool",
      text: "Save hours on color matching and sourcing with our automated tools.",
    },
    {
      title: "User-Friendly Interface",
      text: "Enjoy a seamless user experience tailored for balloon professionals.",
    },
  ];

  const authors = [
    {
      author: "— Nicole G.",
      text: "“Love the color balloon match because it helps make my process as a stylist a lot faster . In just a few minutes, I have a well put together palette that instantly matches the inspiration picture... The fact it gives you three different similar balloon shades for each balloon helps me still have creative freedom.”",
    },
    {
      author: "— Marianna L.",
      text: "“I found this color matching tool incredibly user-friendly and reassuring. It helped me ensure that my chosen decor perfectly captured the essence of my party. With a wide array of balloon colors to choose from, the tool enabled me to pinpoint the exact shade I envisioned with ease.”",
    },
    {
      author: "— Veronica C.",
      text: "“Used the color match feature to select colors for my birthday party and it was SO convenient and FUN! By simply uploading an inspo pic and selecting certain elements, a whole list of balloon colors was carefully curated for me. I was also able to add confetti and tassel colors. This feature just made my event planning a whole lot easier!”",
    },
  ];

  const collapses = [
    {
      title: "Which balloon brands do you have in your database?",
      text: "We currently feature major and popular brands such as Sempertex, Kalisan and Tuftex. We're also expanding our database to include Gemar, Balloonia, and Prima balloons soon.",
    },
    {
      title: "Can I submit a feature to be added?",
      text: "Absolutely! We welcome your feedback and feature suggestions. Please email your ideas to support@ballooncolormatch.com, and our team will keep them in mind for future development.",
    },
    /* {
      title: "What payment methods do you accept?",
      text: "We accept all major credit cards and utilize Stripe for secure payments.",
    },
    {
      title: "How do I cancel my subscription?",
      text: "To cancel your subscription, click on your account name and select 'Profile.' Then, navigate to 'Edit Payment Information' and you will find the option to cancel your subscription on the next screen.",
    }, */
  ];

  const freeAdvantages = [
    { text: "Match balloons to your inspiration" },
    { text: "Find the brand of your preferred colors" },
    { text: "Check balloon size availability" },
    { text: "Manually create color palettes" },
    {
      text: "Discover alternate matches*",
      subtitle: "*Pro feature; limited time only",
    },
    { text: "Access a large library of latex balloons" },
    {
      text: "Access a large library of foil balloons, confetti, tassels, florals & backdrop colors*",
      subtitle: "*Pro feature; limited time only",
    },
    { text: "Export your palettes with ease." },
  ];
  const proAdvantages = [
    { text: "Set preferred brand preferences" },
    { text: "Save and edit your color palettes" },
    { text: "Add your business logo/image" },
    { text: "Custom imports to your palette" },
    { text: "Create a shopping list for easy sourcing" },
    { text: "Access pre-designed palettes" },
  ];

  return (
    <Container className={`p-0 ${style.pricingContainer}`} fluid>
      {!user && (
        <Row
          className={`m-0 align-items-center justify-content-around ${style.pricingSec1}`}
        >
          <Col lg={5} className={style.pricingIntro}>
            <h1 className={style.pricingTitle}>Early Access</h1>
            <h5 className={style.pricingSubtitle}>
              Be among the first to join our community and enjoy some premium
              features before everyone else!
            </h5>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={4}
            xl={3}
            className={style.pricingIntro}
          >
            <Button
              variant="light"
              size="lg"
              className={style.pricingBtn}
              onClick={handleSubscribe}
            >
              SIGN UP FOR FREE
            </Button>
            {/* <p className={style.pricingMount}>Then $6.99/month</p> */}
            {/* <p className={style.pricingInfo}>
              Billed after your 7 days free trial as $6.99/month. Renews
              automatically each month and we will notify you if the price
              increases in advance. Cancel at any time.
            </p> */}
            <p className={style.pricingInfo}>No credit card required.</p>
          </Col>
        </Row>
      )}
      <Row
        className="m-0 px-0 justify-content-around"
        style={{ backgroundColor: "#fafafa" }}
      >
        <PlanSquare
          title="Free"
          subtitle="p/person"
          planInfo="Create stunning palettes and bring your inspiration to life, for free."
          planBodyTitle={`Features you’ll love:`}
          advantages={freeAdvantages}
        />
        <PlanSquare
          title="Pro"
          subtitle="Coming Soon"
          planInfo="Access a wider range of balloons, brands and products. Unlock premium features to run your business more efficiently."
          planBodyTitle="Everything in Free, plus:"
          advantages={proAdvantages}
        />
      </Row>
      <Row
        className={`m-0 px-0 py-5 justify-content-center ${style.advantages} ${
          screenSize > 768 && screenSize < 992 ? "6" : "gap-3"
        }`}
      >
        <h2 className={style.sectionTitle}>What you get</h2>
        {paragraphs.map((p, index) => (
          <Col key={index} md={screenSize > 768 && screenSize < 992 ? 6 : 5}>
            <Row className="justify-content-center">
              <Col xs={1} className={style.checkmarkContainer}>
                <MdDone className={style.paragraphTitle} />
              </Col>
              <Col xs={10} md={7} className={style.paragraphContainer}>
                <h4 className={style.paragraphTitle}>{p.title}</h4>
                <p className={style.paragraph}>{p.text}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <Row
        className={`m-0 align-items-center justify-content-center ${style.directQuote}`}
      >
        {authors.map((a) => (
          <Col
            key={a.author}
            md={screenSize > 768 && screenSize < 992 ? 4 : 3}
            className={style.authors}
          >
            <p className={style.authorsText}>{a.text}</p>
            <p className={style.authorsText}>{a.author}</p>
          </Col>
        ))}
      </Row>
      <Row className={`m-0 px-0 justify-content-center  ${style.questions}`}>
        <h2 className={style.sectionTitle}>Frequently Asked Questions</h2>
        {collapses.map((c, index) => (
          <Col key={index} md={5} lg={10}>
            <Collapse index={index} title={c.title} text={c.text} />
          </Col>
        ))}
      </Row>
      <Footer />
    </Container>
  );
};

export default Pricing;
