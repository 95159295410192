import React, { useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { sizes } from "../../utils/enums";
import { useBalloonsV2 } from "../../utils/data";
import useFilterByBrand from "../../utils/Hooks/useBrandFilter";
import useSizeFilter from "../../utils/Hooks/useSizeFilter";
import AutoCompleteFilters from "../Forms/Inputs/AutoCompleteFilters";
import BalloonOption from "./BalloonOption";
import FilterByBrand from "./FilterByBrand";
import FilterBySize from "./FilterBySize";
import FilterByCategory from "./FilterByCategory";
import FilterByColorCategory from "./FilterByColorCategory";
import useFilterByCategory from "../../utils/Hooks/useCategoryFilter";
import useFilterByColorCategory from "../../utils/Hooks/useColorCategoryFilter";

const Filters = ({ selected, setSelected }) => {
  const { data: balloonsData, isLoading: loadingBalloons } = useBalloonsV2();
  const sizesMemo = useMemo(() => sizes, []);

  const { brandsSelected, handleBrandsChange, filteredBrands } =
    useFilterByBrand(balloonsData);
  const { sizesSelected, handleSizesChange, filteredSizes } =
    useSizeFilter(balloonsData);
  const { categoriesSelected, handleCategoryChange, filteredCategories } =
    useFilterByCategory(balloonsData);
  const {
    colorCategoriesSelected,
    handleColorCategoryChange,
    filteredColorCategories,
  } = useFilterByColorCategory(balloonsData);

  const options = useMemo(() => {
    let result = balloonsData;

    // Aplicar filtro de marcas si se ha filtrado algo
    if (Object.values(brandsSelected).some((selected) => !selected)) {
      result = filteredBrands;
    }

    // Aplicar filtro de tamaños si se ha filtrado algo
    if (Object.values(sizesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredSizes.includes(item));
    }

    // Aplicar filtro de categorías si se ha filtrado algo
    if (Object.values(categoriesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredCategories.includes(item));
    }

    // Aplicar filtro de categorías de color si se ha filtrado algo
    if (Object.values(colorCategoriesSelected).some((selected) => !selected)) {
      result = result.filter((item) => filteredColorCategories.includes(item));
    }

    return result;
  }, [
    balloonsData,
    brandsSelected,
    filteredBrands,
    sizesSelected,
    filteredSizes,
    categoriesSelected,
    filteredCategories,
    colorCategoriesSelected,
    filteredColorCategories,
  ]);

  const renderBalloonOption = (option) => (
    <BalloonOption option={option} sizes={sizesMemo} />
  );

  return (
    <Row className="m-0 p-0">
      <FilterByBrand
        brandsSelected={brandsSelected}
        handleChange={handleBrandsChange}
      />
      <FilterBySize
        sizesSelected={sizesSelected}
        handleChange={handleSizesChange}
      />
      <FilterByCategory
        categoriesSelected={categoriesSelected}
        handleChange={handleCategoryChange}
      />
      <FilterByColorCategory
        colorCategoriesSelected={colorCategoriesSelected}
        handleChange={handleColorCategoryChange}
      />
      <Col xs={12}>
        <AutoCompleteFilters
          title={"balloons"}
          options={options}
          isLoading={loadingBalloons}
          placeholder="Search by name, brand, or category..."
          renderOption={renderBalloonOption}
          selected={selected}
          setSelected={setSelected}
        />
      </Col>
    </Row>
  );
};

export default Filters;
