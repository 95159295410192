import React, { memo, useCallback, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import style from "./Datagrid.module.css";

const FilterComponent = memo(({ filterText, onFilter, onClear }) => (
  <InputGroup
    size="sm"
    className={`${style.filterText} m-3 align-items-center`}
  >
    <Form.Control
      type="text"
      placeholder="Filter By Name"
      value={filterText}
      onChange={onFilter}
    />
    {filterText && <Button onClick={onClear}>X</Button>}
  </InputGroup>
));

const Datagrid = ({ title, columns, data, usersTable }) => {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const handleFilter = useCallback((e) => {
    setFilterText(e.target.value);
  }, []);

  const handleClear = useCallback(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText("");
  }, [resetPaginationToggle]);

  const filteredItems = data.filter(
    (item) =>
      item.displayName ? item.displayName.toLowerCase().includes(filterText.toLowerCase()) : item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  return (
    <Container className={style.container}>
      <Row className={style.datagrid}>
        <Col className="p-0 m-0">
          <DataTable
            defaultSortFieldId={usersTable && 'signupTimestamp'}
            defaultSortAsc={usersTable && false}
            fixedHeader
            fixedHeaderScrollHeight="100%"
            title={title}
            columns={columns}
            data={filteredItems}
            highlightOnHover
            pointerOnHover
            responsive
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            subHeaderComponent={
              <FilterComponent
                filterText={filterText}
                onFilter={handleFilter}
                onClear={handleClear}
              />
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Datagrid;