import React, { useEffect, useState } from "react";
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FaCheck, FaEdit } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { GoTrash } from "react-icons/go";
import Alert from "../../utils/Alert";
import {
  useDeletePalette,
  useDeleteUploadedImagesStorage,
} from "../../utils/data";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import style from "./Profile.module.css";
import { useAppContext } from "../../context";
import { useNavigate } from "react-router-dom";
import { Toaster } from "sonner";

const PaletteCard = ({ palette, handleEditPalette }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    mutate: deleteImagesFromStorage,
    isLoading: deletingImgsFromStorage,
  } = useDeleteUploadedImagesStorage();
  const { mutate: deletePalette, isLoading: deletingPalette } =
    useDeletePalette();

  const {setColormatch, setToolMode, setImages} = useAppContext();
  const navigate = useNavigate();

  const imagePathsToDelete = ({ images }) => {
    return images.map((image) => {
      return deleteImagesFromStorage(image.imagePath);
    });
  };

  const handleDeletePalette = (palette) => {
    try {
      imagePathsToDelete({ images: palette.images });
      deletePalette({ user: palette.user, paletteName: palette.name });
      setTimeout(() => {
        setShowConfirmation(false);
        Alert.success("Palette deleted successfully");
      }, 500);
    } catch (error) {
      console.log(error);
      Alert.error("There was an error submitting");
    }
  };

  const handleCreateNewPalette = ()=>{
    setColormatch([]);
    setImages([]);
    setToolMode('choose')
    navigate('/colormatch');
  }

  useEffect(()=>{
    console.log(showConfirmation);
  }, [showConfirmation])

  return (
    <Col xs={6} md={4} lg={3} xl={3} className={style.paletteCardColumn}>
      {palette.addOne ? (
        <Row className={`p-2 ${style.paletteCard} ${style.paletteAddOne}`} onClick={handleCreateNewPalette}>{palette.addOne}</Row>
      ) : (
        <>
        <a onClick={() => handleEditPalette(palette)} size="sm" className={style.paletteCardLink}>
          <Row className={`p-2 ${style.paletteCard}`}>
            {palette.colors.slice(0, 6).map((balloon, i) => (
              <Col className="p-1 m-0" xs={4} key={i}>
                <Image width="100%" src={balloon.imageURL} />
              </Col>
            ))}
          </Row>
        </a>
          <Row className="align-items-center my-4">
            <Col className="p-0 m-0">
              <h5 className={style.paletteTitle}>{palette.name}</h5>
              {/* <small className={style.paletteDescription}>
                short description
              </small> */}
            </Col>
            <Col xs="auto" className={` p-0 m-0 ${style.paletteActions}`}>
              {/* <Button
                variant="dark"
                onClick={() => handleEditPalette(palette)}
                size="sm"
                className={style.actionBtn}
              >
                <FaEdit />
              </Button> */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip className={style.infoTooltip}>
                    Delete palette
                  </Tooltip>
                }
              >
                <Button
                  variant="dark"
                  onClick={() => setShowConfirmation(true)}
                  size="sm"
                  className={style.actionBtn}
                >
                  <GoTrash />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </>
      )}
      <ModalWindow
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
        title="Delete palette?"
        closeButton
        body={
          <Row className="justify-content-center">
            <Col xs="auto">
              <p>Are you sure you want to delete this color palette? This action cannot be undone.</p>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleDeletePalette(palette)}
                disabled={deletingImgsFromStorage || deletingPalette}
              >
                {deletingImgsFromStorage || deletingPalette ? (
                  "Deleting..."
                ) : (
                  
                  "Yes, delete"
                )}{" "}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                size="sm"
                variant="danger"
                onClick={() => setShowConfirmation(false)}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        }
      />
      <Toaster richColors position="bottom-center"/>
    </Col>
  );
};

export default PaletteCard;
