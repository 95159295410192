import React, { useCallback, useEffect, useRef, useState } from "react";
import { IoShareOutline } from "react-icons/io5";
import { GoLink } from "react-icons/go";
import {
  Button,
  Popover,
  Row,
  Col,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import { serverTimestamp } from "firebase/firestore";
import { useAppContext } from "../../context";
import { useAddPalette } from "../../utils/data";
import Alert from "../../utils/Alert";
import ExportBtn from "../PDF-JEPG/ExportBtn";
import style from "./Commands.module.css";
import { NavLink } from "react-router-dom";
import { imagesMapToUpload } from "../../utils/auxiliarFunctions";

const Share = ({ overlay, setOverlay }) => {
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const { mutate: addPalette, isLoading, isSuccess } = useAddPalette(true);
  const [loading, setLoading] = useState(false);
  const [paletteId, setPaletteId] = useState(null);
  const [dataChange, setDataChange] = useState(false);
  const [options, setOptions] = useState({
    image: false,
    export: null,
  });

  const isInitialMount = useRef(true);

  useEffect(() => {
    // Si no es el primer render, actualiza dataChange
    if (!isInitialMount.current) {
      setDataChange(true);
      setPaletteId(null);
    } else {
      // Después del primer render, cambia el valor del re
      isInitialMount.current = false;
    }
  }, [colormatch, images, options]);

  const handleSharePalette = useCallback(() => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      imagesMapToUpload({ images, setProgress, collection: "sharedPalettes" })
        .then((imagesWithURLs) => {
          const paletteToSave = {
            images: imagesWithURLs,
            colors: colormatch,
            showImage: options.image,
            type: options.export,
            createdAt: serverTimestamp(),
            user: user
          };

          addPalette(paletteToSave, {
            onSuccess: (docRef) => {
              const docId = docRef.id;
              setPaletteId(docId);
              resolve(docId);
            },
            onError: (error) => {
              Alert.error("Error sharing palette, try again.");
              reject(error);
            },
          });
        })
        .catch((error) => {
          Alert.error("Error processing images, try again.");
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [images, colormatch, options.image, options.export, addPalette]);

  const copyToClipboard = async (id) => {
    try {
      /* await navigator.clipboard.writeText(`http://localhost:3000/share/${id}`); */
      await navigator.clipboard.writeText(
        `https://colormatch.tech/share/${id}`
      );
      Alert.success(`Link Copied! The link will expire in 7 days`);
    } catch (err) {
      console.error("Error al copiar el enlace: ", err);
    }
  };

  const copyLinkOfSavedPalette = useCallback(async () => {
    let id = paletteId;
    if (dataChange) {
      setDataChange(false);
      id = await handleSharePalette();
    }
    if (id && !loading) {
      await copyToClipboard(id);
    }
  }, [paletteId, dataChange, loading, handleSharePalette]);

  const popover = (
    <Popover id="popover-share">
      <Popover.Body>
        <Row className="justify-content-center">
          <Col xs={12}>
            <h5 className={style.optionsTitle}>Export Options</h5>
            <Form.Check
              className={style.optionsLabel}
              id="client view"
              label={<p>Share Palette with Client</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "client",
                }))
              }
              checked={options.export === "client"}
            />
            <Form.Check
              className={style.optionsLabel}
              id="internal view"
              label={<p>Share Palette with Balloon Artist</p>}
              name="group1"
              type="radio"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  export: "internal",
                }))
              }
              checked={options.export === "internal"}
            />
          </Col>
          <hr />
          <Col xs={12}>
            <h5 className={style.optionsTitle}>Image Options</h5>
            <Form.Check
              className={style.optionsLabel}
              name="image"
              checked={options.image}
              inline
              id="Include Inspiration Image"
              label="Include Inspiration Image(s)"
              type="checkbox"
              onChange={() =>
                setOptions((prevState) => ({
                  ...prevState,
                  image: !prevState.image,
                }))
              }
            />
          </Col>
          <Col xs={12} className={style.shareBtns}>
            <Button
              variant="dark"
              className={style.shareBtn}
              onClick={copyLinkOfSavedPalette}
              disabled={!options.export || isLoading || loading}
            >
              {loading || isLoading ? (
                "Generating..."
              ) : dataChange || !isSuccess ? (
                <>
                  <GoLink /> Copy Link
                </>
              ) : (
                <>
                  Link Copied!
                  {/* <small>Expires in 7 days</small> */}
                </>
              )}
            </Button>
            <ExportBtn image={options.image} exportOption={options.export} />
          </Col>
          {paletteId && (
            <Col className="mt-2" xs="auto">
              <NavLink
                target="_blank"
                to={`/share/${paletteId}`}
                className={style.optionsSubLabel}
              >
                -- Link --
              </NavLink>
            </Col>
          )}
        </Row>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      containerPadding={20}
      rootClose
      onExit={() =>
        /* //Resetea el state
        setOptions((prevState) => ({
          ...prevState,
          image: false,
          export: null,
        })) */
        setOverlay((prevState) => ({
          ...prevState,
          share: false,
        }))
      }
    >
      <Button
        variant="secondary"
        className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
        disabled={colormatch.length > 1 ? false : true}
        onClick={() =>
          setOverlay((prevState) => ({
            ...prevState,
            share: true,
          }))
        }
      >
        <IoShareOutline /> Share
      </Button>
    </OverlayTrigger>
  );
};

export default Share;
