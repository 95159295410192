import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  setDoc,
  addDoc,
  updateDoc,
  collection,
  getDoc,
  getDocs,
  getFirestore,
  doc,
  deleteDoc,
  writeBatch,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { app, storage } from "../firebase";
import { v4 as uuidv4 } from "uuid";

const db = getFirestore(app);

// --------------- Balloons ---------------
const fetchBalloons = async () => {
  /* let query = [];
  let querySnapshot;
  const querySnapshotCategories = await getDocs(collection(db, "categories"));
  querySnapshotCategories.docs.map((doc) => {
    if (!doc.data().value) {
      query.push(doc.data().name);
      return querySnapshot = getDocs(collection(db, "balloons"));
    } else {
      querySnapshot = getDocs(collection(db, "balloons"));
      return querySnapshot.docs.map((doc) => doc.data());
    }
  }); */
  const querySnapshot = await getDocs(collection(db, "balloons"));
  return querySnapshot.docs.map((doc) => {
    let data = doc.data();
    data.id = uuidv4();
    return data;
  });
};

const addBalloon = async ({ balloon, balloonColor }) => {
  await setDoc(doc(db, "balloons", balloonColor), balloon);
};

const deleteBalloon = async (balloonColor) => {
  await deleteDoc(doc(db, "balloons", balloonColor));
};

export const useBalloons = () => {
  return useQuery("balloons", fetchBalloons);
};

export const useAddBalloon = () => {
  const queryClient = useQueryClient();
  return useMutation(addBalloon, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("balloons");
    },
  });
};

export const useDeleteBalloon = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBalloon, {
    onSuccess: () => {
      queryClient.invalidateQueries("balloons");
    },
    onError: (error) => {
      console.error("Error deleting balloon:", error);
    },
  });
};

// --------------- Balloons V2 ---------------
const fetchBalloonsV2 = async () => {
  const querySnapshot = await getDocs(collection(db, "balloonsv2"));
  return querySnapshot.docs.map((doc) => {
    let data = doc.data();
    data.id = uuidv4();
    return data;
  });
};

const addBalloonV2 = async ({ balloon, balloonColor }) => {
  await setDoc(doc(db, "balloonsv2", balloonColor), balloon);
};

const deleteBalloonV2 = async (balloonColor) => {
  await deleteDoc(doc(db, "balloonsv2", balloonColor));
};

export const useBalloonsV2 = () => {
  return useQuery("balloonsv2", fetchBalloonsV2);
};

export const useAddBalloonV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(addBalloonV2, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("balloonsv2");
    },
  });
};

export const useDeleteBalloonV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBalloonV2, {
    onSuccess: () => {
      queryClient.invalidateQueries("balloonsv2");
    },
    onError: (error) => {
      console.error("Error deleting balloon:", error);
    },
  });
};

// --------------- Users ---------------
const fetchUsers = async () => {
  const querySnapshot = await getDocs(collection(db, "usuarios"));
  return querySnapshot.docs.map((doc) => doc.data());
};

export const useUsers = () => {
  return useQuery("usuarios", fetchUsers);
};

const updateUsers = async (updatedData) => {
  const batch = writeBatch(db);
  for (const property in updatedData) {
    const docRef = doc(db, "usuarios", property);
    batch.update(docRef, { role: updatedData[property].role });
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
  }
};

export const useUpdateUsers = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUsers, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};

const updateUser = async ({ user, userId }) => {
  await updateDoc(doc(db, "usuarios", userId), user);
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("usuarios");
    },
  });
};

// --------------- Categories ---------------
const fetchCategories = async () => {
  const querySnapshot = await getDocs(collection(db, "categories"));
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const useCategories = () => {
  return useQuery("categories", fetchCategories);
};

const setCategories = async (updatedData) => {
  const batch = writeBatch(db);
  for (const property in updatedData) {
    const docRef = doc(db, "categories", property);
    batch.update(docRef, { value: updatedData[property].value });
  }
  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
  }
};

export const useSetCategories = () => {
  const queryClient = useQueryClient();
  return useMutation(setCategories, {
    onSuccess: () => {
      // Invalida y refetch la consulta de "balloons" después de añadir un nuevo globo
      queryClient.invalidateQueries("categories");
    },
  });
};

// --------------- Accessories ---------------
const fetchAccessories = async () => {
  const querySnapshot = await getDocs(collection(db, "accessories"));
  return querySnapshot.docs.map((doc) => {
    let data = doc.data();
    data.id = uuidv4();
    return data;
  });
};

export const useAccessories = () => {
  return useQuery("accessories", fetchAccessories);
};

const fetchAccessoriesCategory = async (category) => {
  const q = query(
    collection(db, "accessories"),
    where("category", "==", category)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const useAccessoriesCategory = (category) => {
  return useQuery(
    ["accessoriesCategory", category],
    () => fetchAccessoriesCategory(category),
    {
      enabled: !!category, // Esto asegura que la consulta solo se ejecute si hay una categoría
    }
  );
};

const addAccessory = async ({ accessory }) => {
  await addDoc(collection(db, "accessories"), accessory);
};

export const useAddAccessories = () => {
  const queryClient = useQueryClient();
  return useMutation(addAccessory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
  });
};

const updateAccessory = async ({ accessory, accessoryId }) => {
  await setDoc(doc(db, "accessories", accessoryId), accessory);
};

export const useUpdateAccessory = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAccessory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
  });
};

const deleteAccesory = async (accId) => {
  const accToDelete = query(
    collection(db, "accessories"),
    where("id", "==", accId)
  );
  const querySnapshot = await getDocs(accToDelete);
  querySnapshot.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

export const useDeleteAccessory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccesory, {
    onSuccess: () => {
      queryClient.invalidateQueries("accessories");
    },
    onError: (error) => {
      console.error("Error deleting accessory:", error);
    },
  });
};

// --------------- Palettes ---------------

const fetchPalette = async (userId, order) => {
  const q = query(
    collection(db, "savedPalettes"),
    where("user", "==", userId),
    orderBy("createdAt", order)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const usePalette = (userId, order) => {
  return useQuery(
    ["savedPalettes", userId, order],
    () => fetchPalette(userId, order),
    {
      enabled: !!userId,
    }
  );
};

const fetchPaletteById = async (docId, shared) => {
  const docRef = doc(db, shared ? "sharedPalettes" : "savedPalettes", docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // paleta existe
    const paletteData = docSnap.data();
    return paletteData;
  } else {
    // paleta no existe
    return null;
  }
};

export const usePaletteById = (docId, shared) => {
  return useQuery([shared ? "sharedPalettes" : "savedPalettes", docId], () =>
    fetchPaletteById(docId, shared)
  );
};

const addPalette = async (palette, shared) => {
  const collectionName = shared ? "sharedPalettes" : "savedPalettes";
  const docRef = await addDoc(collection(db, collectionName), palette);
  return docRef;
};

export const useAddPalette = (shared) => {
  const queryClient = useQueryClient();
  return useMutation((palette) => addPalette(palette, shared), {
    onSuccess: (docRef) => {
      // Accede al ID del documento agregado
      const docId = docRef.id;
      console.log("El ID del documento agregado es: ", docId);
      if (shared) {
        queryClient.invalidateQueries("sharedPalettes");
      } else {
        queryClient.invalidateQueries("savedPalettes");
      }
    },
  });
};

const updatePalette = async ({ palette, paletteId }) => {
  try {
    return await setDoc(doc(db, "savedPalettes", paletteId), palette);
  } catch (error) {
    console.error("Error updating palette:", error);
    throw new Error("Could not update palette");
  }
};

export const useUpdatePalette = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ palette, paletteId }) => updatePalette({ palette, paletteId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("savedPalettes");
      },
    }
  );
};

const deletePalette = async (user, paletteName) => {
  const q = query(
    collection(db, "savedPalettes"),
    where("user", "==", user),
    where("name", "==", paletteName)
  );
  const querySnapshot = await getDocs(q);

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0];
    await deleteDoc(doc(db, "savedPalettes", docSnapshot.id));
  }
};

export const useDeletePalette = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, paletteName }) => deletePalette(user, paletteName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("savedPalettes");
      },
      onError: (error) => {
        console.error("Error deleting palette:", error);
      },
    }
  );
};

// --------------- Images Uploaded ---------------

const deleteUploadedImagesStorage = async (imagePath) => {
  const imageRef = ref(storage, imagePath);
  await deleteObject(imageRef);
};

export const useDeleteUploadedImagesStorage = () => {
  return useMutation(deleteUploadedImagesStorage, {
    onSuccess: () => {
      console.log("File deleted");
    },
    onError: (error) => {
      console.error("Error deleting image:", error);
    },
  });
};
