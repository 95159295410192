import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MdFavoriteBorder } from "react-icons/md";
import { useAppContext } from "../../context";
import { Button, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { app } from "../../firebase";
import {
  useAddPalette,
  usePaletteById,
  useUpdatePalette,
} from "../../utils/data";
import Alert from "../../utils/Alert";
import { imagesMapToUpload } from "../../utils/auxiliarFunctions";
import FormControl from "../Forms/Inputs/FormControl";
import style from "./Commands.module.css";

const db = getFirestore(app);

const Save = ({ overlay, setOverlay }) => {
  const { colormatch, images, user } = useAppContext();
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [paletteName, setPaletteName] = useState("");
  const addPalette = useAddPalette(false);
  const updatePalette = useUpdatePalette();
  const [loading, setLoading] = useState(false);
  const { paletteId } = useParams();
  const { data: palette, isSuccess } = usePaletteById(paletteId, false, {
    enabled: !!paletteId,
  });
  const [savedPalette, setSavedPalette] = useState(false);

  useEffect(() => {
    isSuccess && palette && setPaletteName(palette.name);
  }, [isSuccess, palette]);

  const handleSavePalette = useCallback(async () => {
    setLoading(true);
    try {
      if (!paletteId) {
        const q = query(
          collection(db, "savedPalettes"),
          where("user", "==", user.email),
          where("name", "==", paletteName)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          throw new Error("There is already a palette with this name");
        }
      }
      
      // Si no existe una paleta con el mismo nombre, proceder con la subida de imágenes
      const imagesWithURLs = await imagesMapToUpload({
        images,
        setProgress,
        collection: "savedPalettes",
      });
      const paletteToSave = {
        images: imagesWithURLs,
        colors: colormatch,
        user: user.email,
        name: paletteName,
        createdAt: serverTimestamp(),
      };

      if (paletteId) {
        updatePalette.mutate({
          palette: paletteToSave,
          paletteId: paletteId,
        });
      } else {
        addPalette.mutate(paletteToSave);
      }

      Alert.success(
        `Palette ${
          paletteId ? "updated" : "saved"
        }. View it under 'My Palettes' in your profile.`
      );
    } catch (error) {
      Alert.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
      setSavedPalette(true);
      setTimeout(() => {
        setSavedPalette(false)
      }, 500);
    }
  }, [
    paletteId,
    user.email,
    paletteName,
    images,
    colormatch,
    addPalette,
    updatePalette,
  ]);

  const popover = useMemo(
    () => (
      <Popover id="popover-save">
        <Popover.Body>
          <Row className="justify-content-between">
            <Col xs={8}>
              <FormControl
                placeholder="Palette title"
                value={paletteName}
                name="paletteName"
                onChange={(e) => setPaletteName(e.target.value)}
                size="sm"
              />
            </Col>
            <Col xs={4}>
              <Button
                variant="dark"
                onClick={handleSavePalette}
                className={style.shareBtn}
                disabled={paletteName === ""}
              >
                {loading ? (
                  "Saving..."
                ) : (
                  <>
                    <MdFavoriteBorder />
                    Save
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    ),
    [paletteName, loading, handleSavePalette]
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={popover}
      rootClose
      onExit={() =>
        setOverlay((prevState) => ({
          ...prevState,
          save: false,
        }))
      }
      {...(savedPalette && { show: false })}
    >
      <Button
        variant="secondary"
        className={`${style.actionBtn} ${overlay && style.onTopBtn}`}
        disabled={colormatch.length > 1 ? false : true}
        onClick={() =>
          setOverlay((prevState) => ({
            ...prevState,
            save: true,
          }))
        }
      >
        <MdFavoriteBorder />
        {paletteId ? "Update" : "Save"}
      </Button>
    </OverlayTrigger>
  );
};

export default Save;
