import React, { useState } from "react";
import { Container, Spinner, Row, Col, Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Datagrid from "../../../components/DataGrid/Datagrid";
import style from "../Dashboard.module.css";
import { Toaster } from "sonner";
import Alert from "../../../utils/Alert";
import { useUsers, useUpdateUsers } from "../../../utils/data";
import { FaEdit } from "react-icons/fa";

const roles = [
  { name: "Admin", role: "1" },
  { name: "Visitor", role: "2" },
];

const Users = () => {
  const { data: users, isLoading, isFetching } = useUsers();
  const setUser = useUpdateUsers();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [usersToEdit, setUsersToEdit] = useState({});
  const [edit, setEdit] = useState(false);
  const [enabledSave, setEnabledSave] = useState(false);

  const handleSetEdit = () => {
    setShowUpdateBtn(true);
    setEdit(!edit);
  };

  const handleChangeUserRole = (e, row) => {
    setEnabledSave(true);
    const userToEdit = { ...row };
    userToEdit.role = e.target.value;
    setUsersToEdit((prevState) => ({
      ...prevState,
      [row.email]: userToEdit,
    }));
  };

  const handleSaveChanges = async () => {
    setUser.mutate(usersToEdit);
    if (!setUser.isLoading) {
      setEdit(false);
      setShowUpdateBtn(false);
      setEnabledSave(false);
    }
    Alert.success("Users updated successfully");
  };

  const handleDiscardChanges = () => {
    setUsersToEdit({});
    setEdit(false);
    setShowUpdateBtn(false);
    setEnabledSave(false);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company ? row.company : '',
      sortable: true,
      grow: 2
    },
    {
      name: "Email",
      selector: row=>row.email,
      cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${row.email}`}>
              {row.email}
            </Tooltip>
          }
        >
          <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', width: '100%' }}>
            {row.email.length > 30 ? row.email.substring(0, 30) + '...' : row.email}
          </span>
        </OverlayTrigger>
      ),
      sortable: true,
      grow: 2
    },
    {
      name: "Sign up timestamp",
      selector: (row) => {
        if (row.signupTimestamp) {
          const date = new Date(row.signupTimestamp.seconds * 1000);
          return date;
        }
        return null;
      },
      format: (row) => {
        if (row.signupTimestamp) {
          const date = new Date(row.signupTimestamp.seconds * 1000);
          const options = {
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          };
          return date.toLocaleString('en-US', options);
        }
        return '';
      },
      sortable: true,
      id: 'signupTimestamp',
      grow: 2
    },
    {
      name: "Role",
      selector: (row) =>
        edit ? (
          <Form.Select
            key={row.id}
            onChange={(e) => handleChangeUserRole(e, row)}
            defaultValue={row.role}
          >
            {roles.map((role, index) => {
              return (
                <option key={index} value={role.role}>
                  {role.name}
                </option>
              );
            })}
          </Form.Select>
        ) : !isFetching ? (
          row.role === "1" ? (
          "Admin"
        ) : (
          "Visitor"
        )
      ) : <Spinner as="span" size="sm" animation="grow" />,
      sortable: true,
    },
    {
      name: "Actions",
      selector: () => (
        <Button onClick={handleSetEdit} size="sm">
          <FaEdit size="0.8rem" />
        </Button>
      ),
    },
  ];

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>User List</h1>
        </Col>
      </Row>
      {showUpdateBtn && (
        <Row className="mb-4">
          <Col style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="danger"
              size="sm"
              onClick={handleDiscardChanges}
              className={style.cancelEditBtn}
            >
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={handleSaveChanges} disabled={!enabledSave}>
              Save changes{""}
              {isLoading && (
                <Spinner as="span" size="sm" animation="grow" role="status" />
              )}
            </Button>
          </Col>
        </Row>
      )}
      <Row className={`gap-5 ${style.body} align-items-center`}>
        <Col>
          {!isLoading ? (
            <Datagrid columns={columns} data={users} usersTable={true} />
          ) : (
            <Col style={{ textAlign: "center" }}>
              <Spinner />
            </Col>
          )}
        </Col>
        <Toaster richColors position="bottom-center" />
      </Row>
    </Container>
  );
};

export default Users;