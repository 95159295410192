import React, { useState } from "react";
import { Accordion, Button, Form, Spinner } from "react-bootstrap";
import { useCategories, useSetCategories } from "../../../../utils/data";
import Alert from "../../../../utils/Alert";
import { Toaster } from "sonner";

const Categories = () => {
  const { data: categories, isLoading } = useCategories();
  const setCategories = useSetCategories();
  const [edit, setEdit] = useState({});
  const [show, setShow] = useState(false);

  const handleSwitchChange = (e, row) => {
    setShow(true);
    const categoryObject = { ...row };
    categoryObject.value = e.target.checked;
    setEdit((prevState) => ({ ...prevState, [row.id]: categoryObject }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCategories.mutate(edit);
    if (!setCategories.isLoading) {
      setEdit({});
      setShow(false);
    }
    Alert.success("Categories updated successfully");
  };

  const handleCancel = () => {
    setEdit({});
    setShow(false);
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="categories">
        <Accordion.Header>Categories</Accordion.Header>
        <Accordion.Body>
          <p>Choose categories which will be active in the colormatch tool</p>
          <Form onSubmit={handleSubmit}>
            {!isLoading ? (
              categories.map((category) => (
                <Form.Check
                  key={category.id}
                  type="switch"
                  checked={
                    edit[category.id] ? edit[category.id].value : category.value
                  }
                  name={category.name}
                  onChange={(e) => handleSwitchChange(e, category)}
                  label={category.name}
                />
              ))
            ) : (
              <Spinner />
            )}
            {show && (
              <>
                <Button variant="danger" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="primary" size="sm">
                  Save{" "}
                  {setCategories.isLoading && (
                    <Spinner
                      as="span"
                      size="sm"
                      animation="grow"
                      role="status"
                    />
                  )}
                </Button>
              </>
            )}
          </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Toaster richColors position="bottom-center" />
    </Accordion>
  );
};

export default Categories;
