import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainRoutes from "./MainRoutes";
import Home from "../Pages/Home/Home";
import Pricing from "../Pages/Pricing/Pricing";
import ContactUs from "../Pages/ContactUs/ContactUs";
import Terms from "../Pages/Terms/Terms";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import AuthRoutes from "./AuthRoutes";
import Login from "../Pages/Login/Login";
import SignUp from "../Pages/SignUp/SignUp";
import PasswordReset from "../Pages/PasswordReset/PasswordReset";
import PaymentSuccess from "../Pages/PaymentSuccess/PaymentSuccess";
import ProtectedRoutes from "./ProtectedRoutes";
import Tool from "../Pages/Tool/Tool";
import Users from "../Pages/Dashboard/Users/Users";
import Balloons from "../Pages/Dashboard/Balloons/Balloons";
import Accessories from "../Pages/Dashboard/Accessories/Accessories";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Settings from "../Pages/Dashboard/Settings/Settings";
import Profile from "../Pages/Profile/Profile";
import MyPalettes from "../Pages/Profile/MyPalettes";
import Landing from "../Pages/Landing/Landing";
import AdminRoutes from "./AdminRoutes";
import { useAppContext } from "../context";
import { app } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import SharedPalette from "../Pages/SharedPalette/SharedPalette";

const auth = getAuth(app);
const db = getFirestore(app);
const dbRef = collection(db, "usuarios");

export const AppRoutes = () => {
  const { setUser, setLoading } = useAppContext();

  /* useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => {
          onAuthStateChanged(auth, async (user) => {
            if (user) {
              const q = query(dbRef, where("email", "==", user.email));
              const querySnapshot = await getDocs(q);
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  setUser(doc.data());
                  localStorage.setItem("userRole", doc.data().role);
                  localStorage.setItem(
                    "subscriptionStatus",
                    doc.data().stripeSubscriptionStatus
                  );
                });
              } else {
                localStorage.removeItem("userLoggedIn");
                localStorage.removeItem("userRole");
                localStorage.removeItem("subscriptionStatus");
                console.log("User not found in Firestore");
              }
            }
            resolve();
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [setUser, setLoading]); */

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        try {
          const q = query(dbRef, where("email", "==", user.email));
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              setUser(doc.data());
              localStorage.setItem("userRole", doc.data().role);
              localStorage.setItem(
                "subscriptionStatus",
                doc.data().stripeSubscriptionStatus
              );
            });
            localStorage.setItem("userLoggedIn", "true");
          } else {
            console.log("User not found in Firestore");
            clearUserData();
          }
        } catch (error) {
          console.log(error);
          clearUserData();
        }
      } else {
        clearUserData();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [setUser, setLoading]);

  const clearUserData = () => {
    setUser(null);
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userRole");
    localStorage.removeItem("subscriptionStatus");
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<MainRoutes />}>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Pricing />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/share/:paletteId" element={<SharedPalette />} />
          <Route element={<AuthRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/resetPassword" element={<PasswordReset />} />
            <Route path="/createAccount" element={<PaymentSuccess />} />
            <Route path="/landing" element={<Landing />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/colormatch/:paletteId?" element={<Tool />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/palettes" element={<MyPalettes />} />
            <Route element={<AdminRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="dashboard/balloons" element={<Balloons />} />
              <Route path="dashboard/accessories" element={<Accessories />} />
              <Route path="dashboard/users" element={<Users />} />
              <Route path="dashboard/settings" element={<Settings />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
