import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useAppContext } from "../../../context";
import style from "./PlanSquare.module.css";
import { MdDone } from "react-icons/md";
import { NavLink } from "react-router-dom";

const PlanSquare = ({
  title,
  subtitle,
  planInfo,
  planBodyTitle,
  advantages,
}) => {
  const { user } = useAppContext();

  return (
    <Col xs={10} md={5} lg={4} className={style.planSquare}>
      <Row>
        <Col xs={12}>
          <h2>{title}</h2>
          <section className={style.planSubtitleSec}>
            {title === "Free" && <h2>0$</h2>}
            <p>{subtitle}</p>
          </section>
          <br />
          <p className={style.planInfo}>{planInfo}</p>
          {title === "Free" && !user && (
            <NavLink to="/createAccount">
              <Button variant="secondary" size="lg" className={style.signUpBtn}>
                SIGN UP FOR FREE
              </Button>
            </NavLink>
          )}
        </Col>
      </Row>
      <hr className={style.hr} />
      <Row>
        <Col className="my-3" xs={12}>
          <h4 className={style.planBodyTitle}>{planBodyTitle}</h4>
        </Col>
        {advantages.map((item, index) => (
          <Col className="mb-3" xs={12} key={index}>
            <Row>
              <Col xs={12} className={`m-0 p-0 ${style.planBodyTextCol}`}>
                <Row className="align-items-start">
                  <Col xs={1}>
                    <MdDone className={style.icon} />
                  </Col>
                  <Col xs={10}>
                    <h4 className={style.planBodyText}>{item.text}</h4>
                  </Col>
                </Row>
              </Col>
              {item.subtitle && (
                <Col className="px-5" xs={12}>
                  <p className={style.planBodySubtext}>{item.subtitle}</p>
                </Col>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default PlanSquare;
