import React, { useEffect, useState } from "react";
import { IoMdMove } from "react-icons/io";
import { Button, Col, Row } from "react-bootstrap";
import style from "./Commands.module.css";
import { useAppContext } from "../../context";
import AddButton from "../AddElement/AddButton";
import Share from "./Share";
import Save from "./Save";

const CardsCommands = ({ sortable, setSortable }) => {
  const { colormatch, toolMode } = useAppContext();
  const [showLibraryModal, setShowLibraryModal] = useState(false);
  const [overlay, setOverlay] = useState({
    save: false,
    share: false,
  });

  useEffect(() => {
    toolMode === "palette" && setShowLibraryModal(true);
  }, [toolMode]);

  return (
    <Col
      xs={{ order: 2, span: 12 }}
      lg={{ order: 1, span: 6 }}
      className={`${style.cardsCommandsContainer}`}
    >
      <Row
        className={`p-0 m-0 jusify-content-xs-between ${style.cardsCommandsRow}`}
      >
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <AddButton />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Button
            className={style.actionBtn}
            onClick={() => setSortable(!sortable)}
            variant="secondary"
            disabled={colormatch.length > 1 ? false : true}
          >
            {sortable ? null : <IoMdMove />}
            {sortable ? <span>Save</span> : <span> Reorder</span>}
          </Button>
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Save overlay={overlay.save} setOverlay={setOverlay} />
        </Col>
        <Col xs="auto" className={`p-0 m-0 ${style.cardsCommandsCol}`}>
          <Share overlay={overlay.share} setOverlay={setOverlay} />
        </Col>
      </Row>
      {(overlay.share || overlay.save) && <div className={style.overlay} />}
    </Col>
  );
};

export default CardsCommands;
