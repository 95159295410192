import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import style from "./ContactUs.module.css";
import Footer from "../../components/Footer/Footer";

const ContactUs = () => {
  return (
    <Container fluid className={`p-0 m-0 ${style.contactUsContainer}`}>
      <Row className={`m-0 p-0 align-items-center`}>
        <Col xs={12} md={6} className={`px-4 my-4 ${style.contactUsIntro}`}>
          <Row className="my-4 gap-4 justify-content-center">
            <Col xs={9}>
              <h1 className={style.contactUsTitle}>Contact Us</h1>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsInfo}>
              Our mission is to empower balloon decorators worldwide with an intuitive design tool for crafting beautiful color palettes. 
              We aim to provide reliable color matching capabilities that inspire creativity, simplify planning processes, and contribute to stunning visual results for balloon decorators across the globe.
              </p>
            </Col>
          </Row>
          <Row className="my-2 justify-content-center">
            <Col xs={9}>
              <h5 className={style.contactUsSubtitle}>Questions?</h5>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsData}>
                support@ballooncolormatch.com
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={9}>
              <h5 className={style.contactUsSubtitle}>Corporate</h5>
            </Col>
            <Col xs={9}>
              <p className={style.contactUsData}>
                1900 W Hubbard St, Chicago, IL 60622
              </p>
            </Col>
          </Row>
        </Col>
        <Col className="p-0" xs={12} md={6}>
          <Image
            width="100%"
            src={require("../../assets/ballooncolormatch-contactus.jpg")}
          />
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default ContactUs;
