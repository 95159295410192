import React, { useState } from "react";
import Datagrid from "../../../components/DataGrid/Datagrid";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaEye } from "react-icons/fa";
import style from "../Dashboard.module.css";
import AccessoriesForm from "./AccessoriesForm/AccessoriesForm";
import AccessoriesDetails from "./AccessoryDetail/AccessoryDetails";
import { useAccessories, useAccessoriesCategory } from "../../../utils/data";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";

const Accessories = () => {
  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(false);
  const [imagesLoading, setImagesLoading] = useState({});

  const { data: accessoriesList, isLoading, isFetching } = useAccessories();
  const { data: accessoryCategory } = useAccessoriesCategory("Foils");

  const handleImageLoad = (id) => {
    setImagesLoading((prev) => ({ ...prev, [id]: true }));
  };

  const columns = [
    {
      name: "Image",
      selector: (row) => (
        <>
          {!imagesLoading[row.id] && <Spinner animation="grow" size="sm" />}
          <Image
            style={{
              width: "2rem",
              display: imagesLoading[row.id] ? "block" : "none",
            }}
            src={row.imageURL}
            onLoad={() => handleImageLoad(row.id)}
            rounded
          />
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) =>
        !isFetching ? (
          row.name
        ) : (
          <Spinner as="span" size="sm" animation="grow" />
        ),
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Color Categories",
      selector: (row) =>
        row.colorCategories && row.colorCategories.length > 0
          ? row.colorCategories.map((category, index) => {
              if (index === row.colorCategories.length - 1) {
                return category;
              } else {
                return category + ", ";
              }
            })
          : "-",
      sortable: true,
    },
    {
      name: "Hex Code",
      selector: (row) => row.hexColor && `#${row.hexColor.replace("#", "")}`,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => (
        <>
          <Button
            onClick={() => {
              setShow(true);
              setItemToEdit(row);
            }}
            size="sm"
          >
            <FaEdit size="0.8rem" />
          </Button>

          <Button
            onClick={() => {
              setShowDetails(true);
              setItemToEdit(row);
            }}
            className={style.viewDetailsBtn}
            size="sm"
          >
            <FaEye size="0.8rem" />
          </Button>
        </>
      ),
    },
  ];

  const handleClose = () => {
    setShow(false);
    setItemToEdit(null);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
    setItemToEdit(null);
  };

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Accessories</h1>
        </Col>
        <Col></Col>
      </Row>
      <Row
        className={`gap-3 ${style.body} align-content-center justify-content-center`}
      >
        <Row>
          <Col style={{ display: "flex", justifyContent: "start" }}>
            <Button onClick={() => setShow(true)} variant="secondary" size="sm">
              Upload Accessory
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {!isLoading ? (
              <Datagrid columns={columns} data={accessoriesList} />
            ) : (
              <Col style={{ textAlign: "center" }}>
                <Spinner />
              </Col>
            )}
          </Col>
        </Row>
      </Row>
      <ModalWindow
        show={show}
        onHide={handleClose}
        size="lg"
        fullscreen
        closeButton
        centered
        title={itemToEdit ? "Edit Accessory" : "Upload new Accessory"}
        body={<AccessoriesForm editItem={itemToEdit} setShow={handleClose} />}
      />

      <ModalWindow
        size="lg"
        fullscreen
        show={showDetails}
        onHide={handleCloseDetails}
        closeButton
        title={itemToEdit && itemToEdit.name}
        body={
          <AccessoriesDetails
            item={itemToEdit}
            setShowDetails={handleCloseDetails}
          />
        }
      />
    </Container>
  );
};

export default Accessories;
