import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import style from "./Home.module.css";
import Footer from "../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import Scroller from "../../components/Scroller/Scroller";
import { useAppContext } from "../../context";

const Home = () => {
  const { user } = useAppContext();
  return (
    <Container className={`p-0 m-0 ${style.homeContainer}`} fluid>
      <Row
        className={`px-0 py-4 m-0 gap-5 gap-md-0 justify-content-around align-items-center ${style.homeSec1}`}
      >
        <Col
          xs={{ order: "last", span: 11 }}
          sm={9}
          md={{ order: "first", span: 6 }}
          lg={6}
          className={style.heroImgContainer}
        >
          <Image
            width="85%"
            src={require("../../assets/balloon-color-match-homepage-hero.gif")}
          />
        </Col>
        <Col
          xs={{ order: "first", span: 12 }}
          md={{ order: "last", span: 6 }}
          lg={4}
          className={style.homeIntro}
        >
          <h1 className={style.homeTitle}>Your color palette in seconds.</h1>
          <p className={style.homeSubtitle}>
            Upload your inspiration image, click the colors you love and get
            your perfect balloon color match!
          </p>
          {!user && (
            <NavLink to="/createAccount">
              <Button size="lg" className={style.homeBtn}>
                SIGN UP FREE
              </Button>
            </NavLink>
          )}
        </Col>
      </Row>
      <Row
        className={`p-0 my-5 mx-0 justify-content-center row-gap-3 row-gap-md-5 ${style.homeSec2}`}
      >
        <h2 className={style.homeSecTitle}>
          2 Ways Balloon Color Match
          <br /> Saves You Time and Elevates Your Business
        </h2>
        <Row
          className={`p-0 m-0 gap-5 align-items-center justify-content-center ${style.sec2first}`}
        >
          <Col xs={{ order: "last" }} md={5}>
            <Image
              src={require("../../assets/homepage-inspo-to-balloons.jpg")}
              width="100%"
            />
          </Col>
          <Col
            xs={{ order: "first" }}
            md={{ order: "last", span: 5 }}
            lg={{ offset: 1, span: 4 }}
          >
            <h3 className={style.homeSecSubtitle}>Quick Color Palettes</h3>
            <p className={style.homeSecText}>
              Effortlessly create stunning palettes with balloons, foils,
              confetti, and tassels to impress your clients.
            </p>
          </Col>
        </Row>
        <Row
          className={`p-0 m-0 gap-5 align-items-center justify-content-center ${style.sec2last}`}
        >
          <Col md={5} lg={{ offset: 1, span: 4 }}>
            <h3 className={style.homeSecSubtitle}>Easy Sourcing </h3>
            <p className={style.homeSecText}>
              Instantly find balloon brands and size options you need, all at
              your fingertips.
            </p>
          </Col>
          <Col md={5} lg={{ span: 5 }}>
            <Image
              src={require("../../assets/homepage-sourcing-revised.jpg")}
              width="100%"
            />
          </Col>
        </Row>
      </Row>
      <Row
        className={`m-0 justify-content-center align-items-center ${style.homeSec3}`}
      >
        <h2 className={style.homeSecTitle}>Brands We Feature</h2>
        <Scroller />
      </Row>
      <Footer />
    </Container>
  );
};

export default Home;
