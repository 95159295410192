import { useState, useMemo, useCallback } from "react";
import { sizes } from "../../utils/enums";

const useSizeFilter = (data) => {
  const [sizesSelected, setSizesSelected] = useState(() =>
    Object.fromEntries(
      sizes
        .map((size) => `size${size}`)
        .map((prop) => prop.replace(/\./g, "_"))
        .map((item) => [item, true])
    )
  );

  const handleSizesChange = useCallback((size) => {
    setSizesSelected((prevState) => ({
      ...prevState,
      [size]: !prevState[size],
    }));
  }, []);

  const filteredSizes = useMemo(() => {
    const sizesToExclude = new Set(
      Object.keys(sizesSelected).filter((size) => !sizesSelected[size])
    );
    return data?.filter((balloon) => {
      const balloonTrueSizes = Object.keys(balloon).filter(
        (key) => key.includes("size") && balloon[key] === true
      );
      return !balloonTrueSizes.every((size) => sizesToExclude.has(size));
    });
  }, [sizesSelected, data]);

  return {
    sizesSelected,
    handleSizesChange,
    filteredSizes,
  };
};

export default useSizeFilter;
