import React, { useState, useEffect } from "react";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { Toaster } from "sonner";
import Alert from "../../../../utils/Alert";
import { useDeleteAccessory } from "../../../../utils/data";
import style from "../Accessories.module.css";

const AccessoryDetails = ({ item, setShowDetails }) => {
  const [accessory, setAccessory] = useState({});
  const [confirmDelete, setConfirmDelete] = useState();
  const [deleting, setDeleting] = useState();
  const deleteAccessory = useDeleteAccessory();

  useEffect(() => {
    if (item) {
      setAccessory(item);
    }
  }, [item]);

  const handleSetConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const handleDeleteAccessory = async () => {
    setDeleting(true);
    try {
      deleteAccessory.mutate(accessory.id);
      Alert.success("Accessory deleted successfully");
      setTimeout(() => {
        setShowDetails();
        setDeleting(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      Alert.error("There was an error submitting");
    }
  };

  return (
    <Row className={`m-0 p-0 gap-4 ${style.ballonsDetailsContainer}`}>
      <Col md={4}>
        <Image src={accessory.imageURL} width={260} />
      </Col>
      <Col className={style.balloonDetails}>
        <Row>
          <Col>
            <div>
              <span className={style.detailTitles}>Name: </span>
              <span className={style.detailTexts}>{accessory.name}</span>
            </div>
            <div>
              <span className={style.detailTitles}>Hex Color: </span>
              <span className={style.detailTexts}>
                {accessory.hexColor || "-"}
              </span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Category: </span>
              <span className={style.detailTexts}>{accessory.category}</span>
            </div>
          </Col>
        </Row>
        <Row className={style.deleteBalloonContainer}>
          <Col md={6}>
            <Button size="sm" variant="danger" onClick={handleSetConfirmDelete}>
              <FaTrash /> Delete Accessory
            </Button>
          </Col>
          {confirmDelete && (
            <Row
              className={`justify-content-center ${style.confirmDeleteContainer}`}
            >
              <p className={style.deleteAlert}>
                Deleting this accessory is permanent. Click DELETE to confirm or
                close this window to go back.
              </p>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={handleDeleteAccessory}
                >
                  DELETE
                  {deleting && (
                    <>
                      <span> </span>
                      <Spinner size="sm" animation="grow" />
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Col>
      <Toaster richColors position="bottom-center" />
    </Row>
  );
};

export default AccessoryDetails;
