import React, { useCallback, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { Button, Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import style from "./DropZone.module.css";
import useScreenSize from "../../utils/Hooks/useScreenSize";
import { useAppContext } from "../../context";
import { MdOutlineLibraryAdd } from "react-icons/md";
import Alert from "../../utils/Alert";

const DropZone = ({ btn }) => {
  const { images, setImages, toolMode, setToolMode } = useAppContext();
  const [btnDisabled, setBtnDisabled] = useState(false);

  const screenSize = useScreenSize();

  useEffect(() => {
    if (images.length > 3) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  }, [images]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setImages((prevImages) => [...prevImages, file]);
        setToolMode("tool");
      }
    },
    [setImages, setToolMode]
  );

  const handleDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            Alert.error(
              "Format not accepted. Accepts .jpg, .jpeg, .png, .webp and .gif file types."
            );
          }
        });
      });

      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  return (
    <Dropzone
      onDrop={handleDrop}
      disabled={images.length > 3}
      accept={{
        "image/jpeg": [],
        "image/png": [],
        "image/gif": [],
        "image/webp": [],
      }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <Row
          xs={12}
          {...getRootProps()}
          className={`${
            images.length === 0 && !btn
              ? style.dropzone
              : style.imageUploadedContainer
          } justify-content-center align-items-center`}
          style={
            images.length === 0 && !btn
              ? {
                  backgroundColor: "#ebedef",
                  display: toolMode === "palette" && "none",
                }
              : null
          }
        >
          <input {...getInputProps()} />
          {btn && (
            <Col xs="auto">
              {toolMode === "choose" ? (
                <Button variant="secondary" className={style.chooseBtn}>
                  UPLOAD IMAGE
                </Button>
              ) : btnDisabled ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      Max of 4 images reached
                    </Tooltip>
                  }
                >
                  <div>
                    <Button
                      variant="secondary"
                      size="sm"
                      className={style.actionBtn}
                      disabled={images.length > 3}
                    >
                      <MdOutlineLibraryAdd /> Add image
                    </Button>
                  </div>
                </OverlayTrigger>
              ) : (
                <Button
                  variant="secondary"
                  size="sm"
                  className={style.actionBtn}
                  disabled={images.length > 3}
                >
                  <MdOutlineLibraryAdd /> Add image
                </Button>
              )}
            </Col>
          )}
          {!btn && images.length === 0 && (
            <Col xs="auto" className={style.helperTxtContainer}>
              <p className={`${style.helperTxtTitle} mt-3`}>
                Upload {screenSize >= 992 && "or drag "} image to start.
              </p>
              <p className={style.helperTextSubtitle}>
                Accepts .jpg, .jpeg, .png, .webp and .gif file types (Max. 5MB)
              </p>
              <p
                className={`${style.helperTextSubtitle} ${style.clarification}`}
              >
                Blurry or dark images may not provide the best results.
              </p>
              <p
                className={`${style.helperTextSubtitle} ${style.clarification}`}
              >
                Use high-quality, well-lit images for best results.
              </p>
              <Button
                className={`${style.uploadBtn} btn btn-light btn-md mt-4`}
              >
                Upload
              </Button>
            </Col>
          )}
        </Row>
      )}
    </Dropzone>
  );
};

export default DropZone;
