import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Container, Col, Offcanvas } from "react-bootstrap";
import { getAuth, signOut } from "firebase/auth";
import style from "./HeaderBar.module.css";
import { useAppContext } from "../../context";
import { app } from "../../firebase";
import HamburguerMenu from "./HamburguerMenu";
import useScreenSize from "../../utils/Hooks/useScreenSize";
import DesktopMenu from "./DesktopMenu";
// import SubscriptionStatus from "../../Pages/Dashboard/Profile/SubscriptionStatusListener";

const HeaderBar = () => {
  const auth = getAuth(app);
  const {
    user,
    setUser,
    loading,
    setToolMode,
    setColormatch,
    setImages,
    setDefaultModal,
    shareMode
  } = useAppContext();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [sharePage, setSharePage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = useScreenSize();

  useEffect(() => {
    if (location.pathname.startsWith('/share')) {
      setSharePage(true);
    }else{
      setSharePage(false)
    }
  }, [location.pathname]);

  const handleLogOut = async () => {
    try {
      await signOut(auth);
      setUser(null); // Limpia el estado del usuario en tu contexto
      localStorage.removeItem("userLoggedIn"); // Elimina la marca de sesión
      navigate("/"); // Redirige a la página principal
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  const handleChangeToolMode = (e) => {
    setToolMode("choose");
    setDefaultModal("");
    setShowOffcanvas(false);
    setColormatch([]);
    setImages([]);
    navigate("/colormatch");
  };

  const handleNavLinkClick = () => {
    setShowOffcanvas(false);
  };

  return (
    <Navbar key="md" expand="md" className={style.navbar} fixed="top">
      <Container
        fluid
        className={`align-items-center ${
          screenSize <= 768 ? style.mobile : style.desktop
        }`}
      >
        {screenSize < 768 && (
          <Col className="p-0 m-0" xs={1}>
            <Navbar.Toggle
              onClick={() => setShowOffcanvas(true)}
              className={style.hamburguer}
            />
          </Col>
        )}
        <Navbar.Brand className={style.brandContainer}>
          <NavLink to={"/"} className={style.brand}>
            COLOR MATCH <span>BETA</span>
          </NavLink>
        </Navbar.Brand>
        {sharePage && shareMode === 'internal' || !sharePage ? (


          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            id="offcanvasNavbar-expand-md"
            aria-labelledby="offcanvasNavbarLabel-expand-md"
          >
            <Offcanvas.Header closeButton className={style.offcanvasHeader}>
              {user && (
                <Offcanvas.Title
                  id="offcanvasNavbarLabel-expand-md"
                  className={style.offcanvasTitle}
                >
                  Hi{user && `, ${user.name} `}!
                </Offcanvas.Title>
              )}
            </Offcanvas.Header>
            {screenSize >= 768 ? (
              <DesktopMenu
                user={user}
                loading={loading}
                handleChangeToolMode={handleChangeToolMode}
                handleLogOut={handleLogOut}
              />
            ) : (
              <HamburguerMenu
                user={user}
                loading={loading}
                handleChangeToolMode={handleChangeToolMode}
                handleNavLinkClick={handleNavLinkClick}
                handleLogOut={handleLogOut}
              />
            )}
          </Navbar.Offcanvas>
        ) : null
        }
      </Container>
    </Navbar>
  );
};

export default HeaderBar;
