import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import { sizes } from "../../utils/enums";
import style from "./Filters.module.css";

const SizeOption = ({ size }) => {
  let formattedSize = size.toString().replace(".", "_");
  const sizeLabel =
    size === 6.1
      ? `6" LINK`
      : size === 9.1
      ? `9" LINK`
      : size === 12.1
      ? `12" LINK`
      : size === 13.1
      ? `13" LINK`
      : size === 660.1
      ? `660" LINK`
      : size === 160 || size === 260 || size === 350 || size === 360
      ? size
      : `${size}"`;

  return (
    <Col key={formattedSize} xs={"auto"}>
      <small className={style.rowData}>{sizeLabel}</small>
    </Col>
  );
};

const BalloonOption = memo(({ option }) => (
  <Row className={style.balloonOptionRow}>
    <Col xs={5} md={4} lg={3}>
      <h4 className={style.rowTitle}>SWATCH NAME</h4>
      <small className={style.rowData}>{option.displayName}</small>
    </Col>
    <Col xs={7} md={4} lg={3}>
      <h4 className={style.rowTitle}>BRAND & COLOR NAME</h4>
      {option.combined ? (
        <>
          <h5 className={style.rowSubtitle}>OUTSIDE/INSIDE</h5>
          <div>
            <small className={style.rowData}>
              {option.brand.split("/")[0] === "Ellies"
                ? "Ellie's"
                : option.brand.split("/")[0]}{" "}
              - {option.outsideColor} /{" "}
              {option.brand.split("/")[1] === "Ellies"
                ? "Ellie's"
                : option.brand.split("/")[1]}{" "}
              - {option.insideColor}
            </small>
          </div>
        </>
      ) : (
        option.brand && (
          <small className={style.rowData}>
            {option.brand === "Ellies" ? "Ellie's" : option.brand} -{" "}
            {option.name}
          </small>
        )
      )}
    </Col>
    <Col xs={12} md={4} lg={3}>
      <h4 className={style.rowTitle}>AVAILABLE SIZES</h4>
      <Row>
        {sizes.map(
          (size, index) =>
            option[`size${size.toString().replace(".", "_")}`] && (
              <SizeOption key={index} size={size} />
            )
        )}
      </Row>
    </Col>
  </Row>
));

export default BalloonOption;
