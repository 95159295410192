import React from "react";
import { Col, Dropdown, Form } from "react-bootstrap";
import DropdownBtn from "../Dropdown/DropdownBtn";
import { categories } from "../../utils/enums";

const FilterByCategory = ({ categoriesSelected, handleChange }) => {
  return (
    <Col className="mb-3">
      <DropdownBtn title="Filter by Finish" variant="dark">
        {categories.map((category, index) => (
          <Dropdown.Item
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChange(category);
            }}
          >
            <Form.Check
              type="switch"
              checked={categoriesSelected[category]}
              name={category}
              label={category}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(category);
              }}
              onChange={() => {}}
            />
          </Dropdown.Item>
        ))}
      </DropdownBtn>
    </Col>
  );
};

export default FilterByCategory;
