import React from "react";
import { Button } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useAppContext } from "../../context";
import PDFClient from "./PDFClient";
import PDFInternal from "./PDFInternal";
import { GoDownload } from "react-icons/go";
import { sizes } from "../../utils/enums";
import style from "./ExportBtn.module.css";

const ExportBtn = ({ image, exportOption }) => {
  const { colormatch, images } = useAppContext();

  const pdf = () => {
    if (exportOption === "client") {
      return (
        <PDFClient colors={colormatch} images={images} showImage={image} />
      );
    } else if (exportOption === "internal") {
      return (
        <PDFInternal
          colors={colormatch}
          images={images}
          showImage={image}
          sizes={sizes}
        />
      );
    }
  };

  return (
    <Button variant="dark" className={style.exportBtn} disabled={!exportOption}>
      {exportOption ? (
        <PDFDownloadLink
          className={style.downloadLink}
          document={pdf()}
          fileName="palette.pdf"
        >
          {({ blob, error, loading, url }) => {
            if (loading) return "Processing...";
            if (error) {
              console.log(error);
              return "Error generating";
            }
            return (
              <>
                <GoDownload /> Download
              </>
            );
          }}
        </PDFDownloadLink>
      ) : (
        <>
          <GoDownload /> Download
        </>
      )}
    </Button>
  );
};

export default ExportBtn;
