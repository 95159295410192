import React from "react";
import {
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import style from "../CardsContainer.module.css";

const ViewDetailsRow = ({
  item,
  index,
  sizes,
  addColor,
  replaceColors,
  buttons,
  shared,
}) => {
  return (
    <Row className={`m-0 p-2 ${style.cardRow}`}>
      <Col xs={2} lg={1} className="m-0 p-0" style={{ alignSelf: "center" }}>
        <Image
          src={item.imageURL}
          style={{ aspectRatio: "4/5" }}
          width="100%"
          alt="balloonImg"
        />
      </Col>
      <Col xs={10} lg={11}>
        <Row
          className={`${!buttons && "justify-content-around"} ${
            style.dataCols
          }`}
        >
          {!shared && (
            <Col xs={5} md={4} lg={3}>
              <h3 className={style.tableTitle}>SWATCH NAME</h3>
              <p className={style.data}>{item.displayName || "-"}</p>
              {/* <small>Distance: {item.distance}</small> */}
            </Col>
          )}
          <Col xs={7} md={4} lg={shared ? 5 : 3}>
            <h3 className={style.tableTitle}>BRAND & COLOR NAME</h3>
            {item.combined ? (
              <>
                <h5 className={style.secondaryTitle}>OUTSIDE/INSIDE</h5>
                <p className={style.data}>
                  {item.brand.split("/")[0]} - {item.outsideColor} /{" "}
                  {item.brand.split("/")[1]} - {item.insideColor}
                </p>
              </>
            ) : (
              <>
                <p className={style.data}>
                  {item.brand} - {item.name}
                </p>
              </>
            )}
          </Col>
          <Col xs={12} md={4} lg={shared ? 5 : 3}>
            <h3 className={style.tableTitle}>AVAILABLE SIZES</h3>
            <Row>
              {sizes.map((size) => {
                let formatedSize = size.toString().replace(".", "_");
                return (
                  item[`size${formatedSize}`] && (
                    <Col key={formatedSize} xs={"auto"}>
                      <h3 className={style.tableTitle}>
                        {size === 6.1
                          ? `6" LINK`
                          : size === 9.1
                          ? `9" LINK`
                          : size === 12.1
                          ? `12" LINK`
                          : size === 13.1
                          ? `13" LINK`
                          : size === 660.1
                          ? `660" LINK`
                          : size === 160
                          ? size
                          : size === 260
                          ? size
                          : size === 350
                          ? size
                          : size === 360
                          ? size
                          : `${size}"`}
                      </h3>
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>
          {index > 0 && buttons ? (
            <>
              <Col xs={12} lg={3} className={`${style.rowBtn}`}>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      Add this balloon to palette
                    </Tooltip>
                  }
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => addColor(item)}
                    className={style.actionBtn}
                  >
                    Add to Palette
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip className={style.infoTooltip}>
                      Replace with this balloon
                    </Tooltip>
                  }
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => replaceColors(item)}
                    className={style.actionBtn}
                  >
                    Replace
                  </Button>
                </OverlayTrigger>
              </Col>
            </>
          ) : buttons && index === 0 ? (
            <Col xs={12} lg={3} className={`${style.rowBtn}`}>
              <Button
                variant="dark"
                size="sm"
                disabled
                className={style.actionBtn}
              >
                In Palette
              </Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default ViewDetailsRow;
