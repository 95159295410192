import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GoDot, GoDotFill } from "react-icons/go";
import { Toaster } from "sonner";
import ColorPicker from "../../components/ColorPicker/ColorPicker";
import DropZone from "../../components/DropZone/DropZone";
import style from "./Tool.module.css";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import { useAppContext } from "../../context";
import ToolCommands from "../../components/Commands/ToolCommands";
import CardsCommands from "../../components/Commands/CardsCommands";
import useScreenSize from "../../utils/Hooks/useScreenSize";

function MatchingTool() {
  const { setColormatch, images, setImages } = useAppContext();
  const [sortable, setSortable] = useState(false);
  const [index, setIndex] = useState(0);
  const [indexBrightness, setIndexBrightness] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const screenSize = useScreenSize();

  useEffect(() => {
    setIndex(images.length - 1);
    setIndexBrightness((prevState) => {
      const newBrightnessState = images.map((image, index) => {
        const existingBrightness = prevState.find(
          (item) => item.index === index
        );
        return existingBrightness
          ? existingBrightness
          : { index, brightness: 1 };
      });
      return newBrightnessState;
    });
  }, [images]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleRefresh = () => {
    setSortable(false);
    setColormatch([]);
    setImages([]);
  };

  const goToPrev = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDeleteImage = () => {
    setImages((prevState) =>
      prevState.filter((item) => item !== images[index])
    );
    setIndexBrightness((prevState) =>
      prevState.filter((item, idx) => idx !== index)
    );
    if (images.length > 1 && index !== 0) {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleBrightnessChange = (value) => {
    if (index !== null) {
      setIndexBrightness((prevState) =>
        prevState.map((item) =>
          item.index === index ? { ...item, brightness: value } : item
        )
      );
    }
  };

  const currentBrightness = () => {
    const brightnessObj = indexBrightness.find((item) => item.index === index);
    return brightnessObj ? brightnessObj.brightness : 1;
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <ToolCommands
          handleRefresh={handleRefresh}
          goToPrev={goToPrev}
          goToNext={goToNext}
          handleDeleteImage={handleDeleteImage}
          handleBrightnessChange={handleBrightnessChange}
          indexBrightness={indexBrightness}
          index={index}
          currentBrightness={currentBrightness}
        />
        <CardsCommands sortable={sortable} setSortable={setSortable} />
        {images.length === 0 ? (
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 5 }}
            className={style.colorPickerCol}
          >
            <DropZone />
          </Col>
        ) : (
          <Col
            xs={{ order: 1, span: 12 }}
            md={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 5 }}
            {...(screenSize >= 1400 && screenSize <= 1565
              ? {}
              : { xxl: { order: 2, span: 4 } })}
            /*  xxl={{ order: 2, span: 4 }} */
            className={style.colorPickerCol}
          >
            <ColorPicker
              brightness={currentBrightness()}
              index={index}
              onSelect={handleSelect}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
            {images.length > 1 && (
              <Col
                xs={12}
                lg={12}
                xl={11}
                xxl={10}
                className={style.imagesDots}
              >
                {images.map((image, i) =>
                  index === i ? (
                    <GoDotFill key={i} />
                  ) : (
                    <GoDot
                      key={i}
                      onClick={() => {
                        setIndex(i);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )
                )}
              </Col>
            )}
          </Col>
        )}
        <Col
          xs={{ order: "last", span: 12 }}
          md={{ order: "last", span: 12 }}
          lg={{ order: "last", span: 6 }}
          xl={{ order: "last", span: 5 }}
          className={style.cardsCol}
        >
          <CardsContainer
            sortable={sortable}
            setSelectedColor={setSelectedColor}
          />
        </Col>
      </Row>
      <Toaster richColors position="bottom-center" />
    </Container>
  );
}

export default MatchingTool;
