import React, {useState, useEffect} from 'react';
import {Container, Spinner, Row, Col, Button} from "react-bootstrap";
import { useLocation } from 'react-router-dom';
import SignUp from '../SignUp/SignUp';
import axios from 'axios';

const PaymentSuccess = ()=>{
  const location = useLocation();
  const [sessionId, setSessionId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [customerName, setCustomerName] = useState(null);

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const sessionIdParam = params.get('session_id');
    setSessionId(sessionIdParam);
    
    if(sessionId){
      //console.log("entra");

      axios.get(`https://vps-3348494-x.dattaweb.com:5455/verify-session`, {
        params:{
          session_id: sessionId
        }
      })
      .then(response =>{
        setCustomerId(response.data.customer);
        setCustomerEmail(response.data.customer_details.email);
        setCustomerName(response.data.customer_details.name);
        //console.log('Data de la sesión: ', response.data.customer_details);
      })
      .catch(error =>{
        console.log("Error al obtener data de la sesión: ", error);
      })
    }else{
      //console.log("no entra");
    }

  }, [location, sessionId])

  return(
    <SignUp sessionId={sessionId} customerId={customerId} customerEmail={customerEmail} customerName={customerName} />
  )
}

export default PaymentSuccess;