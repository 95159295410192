import React, { useRef, useState, useEffect, useCallback } from "react";
import { Carousel } from "react-bootstrap";
import style from "./CanvasWithZoom.module.css";
import Alert from "../../utils/Alert";
import { colorMatchFn } from "../../utils/auxiliarFunctions";
import { useAppContext } from "../../context";
import { useBalloons, useBalloonsV2 } from "../../utils/data";
import { loadImage, drawZoom } from "../../utils/auxiliarFunctions";
import Slider from "../Slider/Slider";

const CanvasWithZoom = ({
  selectedColor,
  setSelectedColor,
  brightness,
  index,
  onSelect,
}) => {
  const { colormatch, setColormatch, images } = useAppContext();
  const { data: balloonsData, isLoading } = useBalloonsV2();
  const canvasRefs = useRef([]);
  const zoomRef = useRef(null);
  const [showZoom, setShowZoom] = useState(false);
  const [mousePositions, setMousePositions] = useState([]);

  useEffect(() => {
    if (images.length > 0) {
      images.forEach((item, index) => {
        loadImage(canvasRefs.current[index], item, brightness);
      });
    }
  }, [images, brightness]);

  useEffect(() => {
    const handleMouseMove = (event, index) => {
      const canvas = canvasRefs.current[index];
      if (canvas) {
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setMousePositions((prev) => {
          const newPositions = [...prev];
          newPositions[index] = { x, y };
          return newPositions;
        });
      }
    };

    const handleMouseLeave = (index) => {
      setMousePositions((prev) => {
        const newPositions = [...prev];
        newPositions[index] = null;
        return newPositions;
      });
    };

    canvasRefs.current.forEach((canvas, index) => {
      if (canvas) {
        const moveHandler = (event) => handleMouseMove(event, index);
        const leaveHandler = () => handleMouseLeave(index);

        canvas.addEventListener("mousemove", moveHandler);
        canvas.addEventListener("mouseleave", leaveHandler);

        return () => {
          canvas.removeEventListener("mousemove", moveHandler);
          canvas.removeEventListener("mouseleave", leaveHandler);
        };
      }
    });
  }, [images]);

  useEffect(() => {
    if (showZoom && zoomRef.current) {
      mousePositions.forEach((pos, index) => {
        if (pos) {
          drawZoom(zoomRef.current, canvasRefs.current[index], pos);
        }
      });
    }
  }, [showZoom, mousePositions]);

  const handleCanvasMouseMove = () => {
    setShowZoom(true);
  };

  const handleCanvasMouseLeave = () => {
    setShowZoom(false);
  };

  const rgbToHex = useCallback(
    (r, g, b) =>
      `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
        .toString(16)
        .padStart(2, "0")}`,
    []
  );

  const handleCanvasClick = useCallback(
    (event, index) => {
      const canvas = canvasRefs.current[index];
      const ctx = canvas.getContext("2d");
      const x = event.nativeEvent.offsetX;
      const y = event.nativeEvent.offsetY;

      const pixel = ctx.getImageData(x, y, 1, 1).data;
      const color = rgbToHex(pixel[0], pixel[1], pixel[2]);
      if (color === selectedColor) {
        Alert.error("You have already clicked this color.");
      } else {
        setSelectedColor(color);
        colorMatchFn(balloonsData, isLoading, colormatch, setColormatch, color);
      }
    },
    [
      selectedColor,
      setSelectedColor,
      balloonsData,
      isLoading,
      colormatch,
      setColormatch,
      rgbToHex,
    ]
  );

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <Slider
        index={index}
        onSelect={onSelect}
        controls={false}
        indicators={false}
        interval={null}
      >
        {images.map((_, index) => (
          <Carousel.Item key={index}>
            <canvas
              ref={(el) => (canvasRefs.current[index] = el)}
              alt="Canvas"
              className={style.canvas}
              onClick={(event) => handleCanvasClick(event, index)}
              onMouseMove={handleCanvasMouseMove}
              onMouseLeave={handleCanvasMouseLeave}
            />
          </Carousel.Item>
        ))}
      </Slider>
      {showZoom && (
        <div className={style.canvasZoom}>
          <canvas
            ref={zoomRef}
            alt="Zoom"
            width="50"
            height="50"
            style={{ borderRadius: "50%", border: "solid 1px #292929" }}
          />
        </div>
      )}
    </div>
  );
};

export default CanvasWithZoom;
