import React from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  Col,
  Nav,
  NavDropdown,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { MdFavoriteBorder, MdLogout } from "react-icons/md";
//import { GrUserSettings } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import style from "./HeaderBar.module.css";

const DesktopMenu = ({ user, loading, handleChangeToolMode, handleLogOut }) => {
  return (
    <Offcanvas.Body>
      <Nav className={style.offcanvasNav}>
        <Row
          className={`p-0 m-0 justify-content-between ${style.offcanvasNav}`}
        >
          <Col className={`m-0 ${style.productLink}`} md={2}>
            <NavLink className={`${style.links}`} to="/product">
              Product
            </NavLink>
          </Col>
          <Col className="p-0 m-0" md={10}>
            <Row className={`p-0 m-0 align-items-center justify-content-end`}>
              {loading ? (
                <Col md={2}>
                  <Spinner animation="grow" size="sm" variant="dark" />
                </Col>
              ) : user ? (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <Button
                      variant="secondary"
                      className={style.links}
                      onClick={handleChangeToolMode}
                    >
                      Create New Palette
                    </Button>
                  </Col>
                  {user.role === "1" && (
                    <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                      <NavLink className={style.links} to="/dashboard">
                        Dashboard
                      </NavLink>
                    </Col>
                  )}
                  <Col className="p-0 m-0" md="auto">
                    <NavDropdown title={user.name} className={style.links}>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/profile"
                        className={style.userOptions}
                      >
                        <IoSettingsOutline />
                        Account Settings
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/profile/palettes"
                        className={style.userOptions}
                      >
                        <MdFavoriteBorder />
                        My Palettes
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={handleLogOut}
                        className={style.userOptions}
                      >
                        <MdLogout size="1rem" />
                        Log out
                      </NavDropdown.Item>
                      <NavDropdown.Divider className={style.dropdownDivider} />
                      <NavDropdown.Item
                        as={NavLink}
                        to="/terms"
                        className={style.sublinks}
                      >
                        Terms
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/privacyPolicy"
                        className={style.sublinks}
                      >
                        Privacy Policy
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={NavLink}
                        to="/contactUs"
                        className={style.sublinks}
                      >
                        Contact Us
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Col>
                </>
              ) : (
                <>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLink className={style.links} to="/createAccount">
                      Sign Up
                    </NavLink>
                  </Col>
                  <Col className={`p-0 m-0 ${style.navMenuItem}`} md="auto">
                    <NavLink className={style.links} to="/login">
                      Log In
                    </NavLink>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Nav>
    </Offcanvas.Body>
  );
};

export default DesktopMenu;
