import { useState, useMemo, useCallback } from "react";
import { colorCategories } from "../../utils/enums";

const useFilterByColorCategory = (data) => {
  const [colorCategoriesSelected, setColorCategoriesSelected] = useState(() =>
    Object.fromEntries(
      colorCategories.map((colorCategory) => [colorCategory.name, true])
    )
  );

  const handleColorCategoryChange = useCallback(
    (colorCategory) => {
      setColorCategoriesSelected((prevState) => ({
        ...prevState,
        [colorCategory]: !prevState[colorCategory],
      }));
    },
    [setColorCategoriesSelected]
  );

  const filteredColorCategories = useMemo(() => {
    const colorCategoriesToExclude = new Set(
      Object.keys(colorCategoriesSelected).filter(
        (colorCategory) => !colorCategoriesSelected[colorCategory]
      )
    );
    return data?.filter((balloon) => {
      return !balloon.colorCategories.some((colorCategory) =>
        colorCategoriesToExclude.has(colorCategory)
      );
    });
  }, [colorCategoriesSelected, data]);

  return {
    colorCategoriesSelected,
    handleColorCategoryChange,
    filteredColorCategories,
  };
};

export default useFilterByColorCategory;
