import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePaletteById } from "../../utils/data";
import {
  Col,
  Container,
  Row,
  Spinner,
  Image,
  Carousel,
  Button,
} from "react-bootstrap";
import ColorCard from "../../components/ColorCards/ColorCard";
import Slider from "../../components/Slider/Slider";
import ViewDetailsRow from "../../components/CardsContainer/ViewDetails/ViewDetailsRow";
import { sizes } from "../../utils/enums";
import { GoDot, GoDotFill } from "react-icons/go";
import { GrNext, GrPrevious } from "react-icons/gr";
import style from "./SharedPalette.module.css";
import { useAppContext } from "../../context";

const SliderImages = ({ images, index, setIndex, onSelect }) => (
  <Col xs={10} md={6} lg={3} xl={4}>
    <Slider
      index={index}
      onSelect={onSelect}
      controls={false}
      indicators={false}
      interval={null}
    >
      {images.map((image, idx) => (
        <Carousel.Item key={idx}>
          <Image width="100%" src={image.imageURL} />
        </Carousel.Item>
      ))}
    </Slider>
    {images.length > 1 && (
      <Row className="justify-content-center align-items-center my-4">
        <Col xs="auto">
          <Button
            variant="dark"
            onClick={() =>
              setIndex((prevIndex) =>
                prevIndex === 0 ? images.length - 1 : prevIndex - 1
              )
            }
            disabled={index === 0}
            size="sm"
            className={style.actionBtn}
          >
            <GrPrevious />
          </Button>
        </Col>
        <Col xs="auto">
          {images.map((_, i) =>
            index === i ? (
              <GoDotFill key={i} />
            ) : (
              <GoDot
                key={i}
                onClick={() => onSelect(i)}
                style={{ cursor: "pointer" }}
              />
            )
          )}
        </Col>
        <Col xs="auto">
          <Button
            variant="dark"
            onClick={() =>
              setIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
              )
            }
            disabled={images.length - 1 === index}
            size="sm"
            className={style.actionBtn}
          >
            <GrNext />
          </Button>
        </Col>
      </Row>
    )}
  </Col>
);

const SharedPalette = () => {
  const { paletteId } = useParams();
  const {
    data: palette,
    isLoading,
    isSuccess,
  } = usePaletteById(paletteId, true);
  const [index, setIndex] = useState(0);

  const { shareMode, setShareMode } = useAppContext();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(()=>{
    if(palette?.type === 'client'){
      setShareMode('client')
    }else if(palette?.type === 'internal'){
      setShareMode('internal')
    }
  }, [palette, shareMode])

  console.log(shareMode)

  const clientPalette = (
    <Row className="mt-5">
      {palette?.showImage && (
        <SliderImages
          images={palette.images}
          index={index}
          setIndex={setIndex}
          onSelect={handleSelect}
        />
      )}
      <Col md={6} xl={8}>
        <Row id="cardsContainer" xs={2} md={3} lg={6} className="row-gap-4">
          {palette?.colors.map((color, index) => (
            <ColorCard key={index} color={color} share={true} />
          ))}
        </Row>
      </Col>
    </Row>
  );

  const internalPalette = (
    <Row className="justify-content-around">
      {palette?.showImage && (
        <SliderImages
          images={palette.images}
          index={index}
          setIndex={setIndex}
          onSelect={handleSelect}
        />
      )}
      <Col xs={12} md={8} lg={7} className={`p-0 ${style.infoContainer}`}>
        {palette?.colors.map((color, index) => (
          <ViewDetailsRow
            key={color.id}
            item={color}
            index={index}
            sizes={sizes}
            buttons={false}
            shared={true}
          />
        ))}
      </Col>
    </Row>
  );

  const returnPalette = () => {
    if (!palette) return null;

    return palette.type === "client"
      ? clientPalette
      : palette.type === "internal" && internalPalette;
  };

  return (
    <Container className="my-5">
      {isLoading ? (
        <Row className="justify-content-center align-items-center">
          <Col xs="auto">
            <Spinner animation="grow" size="sm" variant="dark" />
          </Col>
        </Row>
      ) : isSuccess && !palette ? (
        <Row className="justify-content-center align-items-center">
          <Col xs="auto">
            <p className={style.expiredMessage}>
              The link has expired or the palette does not exist : (
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <h1 className={style.sharedPaletteTitle}>Color Palette</h1>
          <p className={style.paletteUserInfo}>{palette.user.company ? palette.user.company + ' - ' : null}  {palette.user.name}</p>
          {returnPalette()}
        </>
      )}
    </Container>
  );
};

export default SharedPalette;
