import React from "react";
import { Modal } from "react-bootstrap";
import style from "./ModalWindow.module.css";
import useScreenSize from "../../utils/Hooks/useScreenSize";

const ModalWindow = ({
  show,
  onHide,
  centered,
  size,
  fullscreen,
  closeButton,
  title,
  header,
  body,
  footer,
}) => {
  const screenSize = useScreenSize();

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={centered}
      size={size}
      fullscreen={fullscreen && screenSize < 992 && true}
      className={style.modalWindow}
    >
      {title || header ? (
        <Modal.Header className={style.header} closeButton={closeButton}>
          {title ? (
            <Modal.Title className={style.modalTitle}>{title}</Modal.Title>
          ) : (
            header
          )}
        </Modal.Header>
      ) : (
        closeButton && <Modal.Header closeButton={closeButton} />
      )}
      <Modal.Body>{body}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalWindow;
