import React, { useState } from "react";
import style from "../Login/Login.module.css";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Alert,
  Spinner,
  InputGroup,
} from "react-bootstrap";

import { app } from "../../firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { Toaster } from "sonner";
import { default as AlertSonner } from "../../utils/Alert";
import { GoEye, GoEyeClosed } from "react-icons/go";

const db = getFirestore(app);

const auth = getAuth(app);

const SignUp = (props) => {
  const [error, setError] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [email, setEmail] = useState(props.customerEmail || "");
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [newUser, setNewUser] = useState({
    password: "",
    coincidentPassword: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  /* useEffect(()=>{
    handleGetSubscriptionStatus();
  }, [])

  const handleGetSubscriptionStatus = ()=>{
    axios.get(`https://vps-3348494-x.dattaweb.com:5455/subscription-status`, {
      params:{
        customer_id: props.customerId
      }
    })
    .then(response =>{
      setSubscriptionStatus(response.data[0].status);
      //console.log('Data de la suscripción: ', response.data);
    })
    .catch(error =>{
      console.log("Error al obtener estado de la suscripción: ", error);
    })
  } */

  const handlePasswordChange = (e) => {
    const name = e.target.name;
    if (name === "password") {
      if (e.target.value.length > 5) {
        setNewUser({ ...newUser, password: e.target.value, active: true });
      } else {
        setNewUser({ ...newUser, password: e.target.value, active: false });
      }
    } else if (name === "coincidentPassword") {
      setNewUser({ ...newUser, coincidentPassword: e.target.value });
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSendWelcomeEmail = async (dataEmail) => {
    try {
      /* const response = await axios.post("http://localhost:4242/send-welcome-email", dataEmail, { */
      const response = await axios.post(
        "https://vps-3348494-x.dattaweb.com:5455/send-welcome-email",
        dataEmail,
        {
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 60000, // Timeout de 60 segundos
        }
      );
      //console.log("Respuesta del servidor:", response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const name = e.target.name.value;
    const lastName = e.target.lastName.value;
    const company = e.target.company.value;
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email === "") {
      setLoading(false);
      return AlertSonner.error("You must enter an email");
    }

    if (name === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a name");
    }

    if (lastName === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a last name");
    }

    if (password === "") {
      setLoading(false);
      return AlertSonner.error("You must enter a password");
    }

    if (password && e.target.coincidentPassword) {
      console.log();
      if (e.target.coincidentPassword.value === "") {
        setLoading(false);
        return AlertSonner.error("You must confirm the password");
      }
      if (e.target.coincidentPassword.value !== password) {
        setLoading(false);
        return AlertSonner.error("Passwords must match");
      }
    }

    if (!checkboxChecked) {
      setLoading(false);
      return AlertSonner.error(
        "You must agree Terms and Privacy policy to create an account"
      );
    }

    const nuevoUsuario = {
      email: email,
      name: name,
      lastName: lastName,
      company: company,
      role: "2",
      stripeCheckoutSessionId: props.sessionId || "",
      stripeCustomerId: props.customerId || "",
      stripeSubscriptionStatus: subscriptionStatus || "",
      signupTimestamp: new Date(),
    };

    //console.log("Usuario a enviar: ", nuevoUsuario);

    try {
      const dataEmail = {
        email: nuevoUsuario.email,
        name: nuevoUsuario.name,
      };
      await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "usuarios", email), nuevoUsuario);
      await signInWithEmailAndPassword(auth, email, password);

      //await handleSendWelcomeEmail(dataEmail);

      // Llamar a handleSendWelcomeEmail de manera independiente para que no corte la ejecución. Sin await
      handleSendWelcomeEmail(dataEmail).catch((error) => {
        console.error(error);
      });

      localStorage.setItem("userLoggedIn", true);
      setError("");
      setUserCreated(true);

      setLoading(false);
      navigate("/colormatch");
    } catch (error) {
      setLoading(false);
      if (error.code === "auth/email-already-in-use") {
        AlertSonner.error("An account with this email address already exists");
      }
      if (error.code === "auth/missing-password") {
        setError("You must enter a password");
      }
      console.log("Error al crear el usuario: ", error);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid={true}>
      <Row className={style.rowContainer}>
        <Col className={style.colContainer}>
          <h1 className={style.title}>Create an account</h1>
          <Card className={style.card}>
            <Card.Body>
              <NavLink to="/login" className={style.register}>
                Already have an account?{" "}
                <span className={style.registerSignupTxt}>Log in.</span>
              </NavLink>
              <Form onSubmit={handleSubmit} className="mt-4">
                <Form.Group className="mb-3">
                  <Form.Label className={style.formLabels}>
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={props.customerEmail ? props.customerEmail : email}
                    onChange={handleEmailChange}
                    className={style.formInputs}
                    /* disabled={props.customerEmail ? true : false} */
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className={style.formLabels}>
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        name="name"
                        className={style.formInputs}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className={style.formLabels}>
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        className={style.formInputs}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label className={style.formLabels}>
                    Company (optional)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    name="company"
                    className={style.formInputs}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className={style.formLabels}>Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      isInvalid={
                        newUser.password.length > 0 &&
                        newUser.password.length < 6
                      }
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={newUser.password}
                      onChange={handlePasswordChange}
                      className={style.formInputs}
                    />
                    {showPassword ? (
                      <Button
                        className={style.showPswBtn}
                        variant="dark"
                        onClick={handleShowPassword}
                      >
                        <GoEye />
                      </Button>
                    ) : (
                      <Button
                        className={style.showPswBtn}
                        variant="dark"
                        onClick={handleShowPassword}
                      >
                        <GoEyeClosed />
                      </Button>
                    )}
                    <Form.Control.Feedback type="invalid">
                      Minimum 6 characters
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                {newUser.password && (
                  <Form.Group
                    className={`mb-3 ${style.confirmPassword} ${
                      newUser.active && style.confirmPasswordActive
                    }`}
                  >
                    <Form.Label className={style.formLabels}>
                      Confirm password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="coincidentPassword"
                      value={newUser.coincidentPassword}
                      onChange={handlePasswordChange}
                      isValid={newUser.password === newUser.coincidentPassword}
                      isInvalid={
                        newUser.password !== newUser.coincidentPassword
                      }
                      className={style.formInputs}
                    />
                    <Form.Control.Feedback>
                      Matching passwords
                    </Form.Control.Feedback>
                    {newUser.coincidentPassword.length !== 0 && (
                      <Form.Control.Feedback type="invalid">
                        Missmatched passwords
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
                {error !== "" ? (
                  <span className={style.errorTxt}>{error}</span>
                ) : null}
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="agree-terms"
                    name="agreeTerms"
                    className={style.agreeTermsCheck}
                  >
                    <Form.Check.Input
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      checked={checkboxChecked}
                    />
                    <Form.Check.Label>
                      By creating an account, I agree to Balloon Color Match{" "}
                      <NavLink to="/terms">Terms</NavLink> and{" "}
                      <NavLink to="/privacyPolicy">Privacy Policy.</NavLink>
                    </Form.Check.Label>
                  </Form.Check>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className={style.loginBtn}
                >
                  Sign Up{" "}
                  {loading && (
                    <Spinner
                      as="span"
                      size="sm"
                      animation="grow"
                      role="status"
                    />
                  )}
                </Button>
              </Form>
              {userCreated ? (
                <Alert key="secondary" variant="dark" className="mt-4">
                  Successfully created user.{" "}
                  <NavLink
                    to="/login"
                    className={style.goToLoginRegisterSuccess}
                  >
                    Go to Log in.
                  </NavLink>
                </Alert>
              ) : null}
            </Card.Body>
          </Card>
          <Toaster richColors position="bottom-center" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
