import React from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import style from "./Tool.module.css";
import MatchingTool from "./MatchingTool";
import { useAppContext } from "../../context";
import DropZone from "../../components/DropZone/DropZone";
import useScreenSize from "../../utils/Hooks/useScreenSize";
import { dropdownOptions } from "../../utils/enums";
import { Toaster } from "sonner";

const Tool = () => {
  const { toolMode, setToolMode, setDefaultModal } = useAppContext();
  const screenSize = useScreenSize();

  return (
    <Container fluid className="p-0 m-0 mb-3">
      {screenSize < 768 && (
        <div className={style.mobileCopy}>
          For the best experience, please use our web app on a desktop. Mobile
          version coming soon!
        </div>
      )}
      {toolMode === "choose" ? (
        <Row className="m-0 p-0 justify-content-center">
          <h1 className={style.toolTitle}>2 WAYS TO CREATE A PALETTE</h1>
          <Col xs={11} md={4} className={style.section}>
            <div className={style.sectionContent}>
              <h3 className={style.mode}>Color Match</h3>
              <p className={style.tool}>
                Upload an image and click on the spot of your desired colors to
                find the balloon match
              </p>
              <DropZone btn={true} />
            </div>
            {/* <div className={style.flexSpacer}></div> */}
            <div className={style.textWrapper}>
              <p className={style.uploadImageCopy}>
                Accepts .jpeg, .png, and .gif file types. (Max. 5MB)
                <br /> Blurry or dark images may not provide the best results.
                Use high-quality, well-lit images for best results.
              </p>
            </div>
          </Col>
          <Col xs={11} className={style.section}>
            <div className={style.sectionContent}>
              <h3 className={style.mode}>From Library</h3>
              <p className={style.tool}>
                Explore & add from our library of popular balloons to quickly
                create a palette
              </p>
              <Dropdown id={style.addButton}>
                <Dropdown.Toggle
                  variant="dark"
                  id="dropdown-basic"
                  className={style.btn}
                >
                  ADD FROM LIBRARY
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownOptions.map((option, index) => {
                    const selectedValue = { title: option };
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          setToolMode("palette");
                          setDefaultModal(selectedValue.title);
                        }}
                        key={index}
                      >
                        {selectedValue.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={style.textWrapper}>
              <p className={style.uploadImageCopy}></p>
            </div>
          </Col>
        </Row>
      ) : (
        <MatchingTool />
      )}
      <Toaster richColors position="bottom-center" />
    </Container>
  );
};

export default Tool;
