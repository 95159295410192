import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import Alert from "./Alert";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";

export const colorMatchFn = async (
  balloonsData,
  isLoading,
  colormatch,
  setColormatch,
  elementSelected,
  options = {}
) => {
  const { insert } = options;

  if (!isLoading && elementSelected) {
    try {
      const response = await axios.post(
        process.env.REACT_APP_COLORMATCHFN_PROD_URL,
        {
          balloonsData,
          elementSelected,
          colormatch,
          options,
          /* model2: true */
        }
      );

      const { closerColor, nearestColorArray } = response.data;

      if (nearestColorArray.length > 0) {
        if (
          colormatch.some((item) => nearestColorArray[0].color === item.color)
        ) {
          return Alert.error("There is already a balloon with this color.");
        }
      }

      if (insert || insert === 0) {
        const copyColorMatch = [...colormatch];
        copyColorMatch.splice(insert, 1, closerColor);
        return setColormatch(copyColorMatch);
      }

      setColormatch((prevState) => [...prevState, closerColor]);
    } catch (error) {
      console.error("Error fetching color match:", error);
    }
  }
};

export const loadImage = (canvas, image, brightness, callback) => {
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.onload = () => {
    const aspectRatio = img.width / img.height;
    const maxWidth = window.innerWidth <= 576 ? 380 : 500;
    const maxHeight = maxWidth / aspectRatio;

    canvas.width = maxWidth;
    canvas.height = maxHeight;

    ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
    applyBrightness(ctx, brightness);
    callback && callback();
  };

  if (image) img.src = URL.createObjectURL(image);
};

const applyBrightness = (ctx, brightness) => {
  const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  const data = imageData.data;
  for (let i = 0; i < data.length; i += 4) {
    data[i] = Math.min(255, data[i] * brightness); // Red
    data[i + 1] = Math.min(255, data[i + 1] * brightness); // Green
    data[i + 2] = Math.min(255, data[i + 2] * brightness); // Blue
  }
  ctx.putImageData(imageData, 0, 0);
};

export const drawZoom = (zoomCanvas, canvas, mousePosition, zoomSize = 10) => {
  const zoomCtx = zoomCanvas.getContext("2d");
  const x = mousePosition.x;
  const y = mousePosition.y;

  zoomCtx.clearRect(0, 0, zoomCanvas.width, zoomCanvas.height);

  const correctedX = x - zoomSize / 2 + 0.5;
  const correctedY = y - zoomSize / 2 + 0.5;

  zoomCtx.drawImage(
    canvas,
    correctedX,
    correctedY,
    zoomSize,
    zoomSize,
    0,
    0,
    zoomCanvas.width,
    zoomCanvas.height
  );

  zoomCtx.strokeStyle = "black";
  zoomCtx.lineWidth = 0.5;
  zoomCtx.beginPath();
  zoomCtx.moveTo(zoomCanvas.width / 2 - 10, zoomCanvas.height / 2);
  zoomCtx.lineTo(zoomCanvas.width / 2 + 10, zoomCanvas.height / 2);
  zoomCtx.moveTo(zoomCanvas.width / 2, zoomCanvas.height / 2 - 10);
  zoomCtx.lineTo(zoomCanvas.width / 2, zoomCanvas.height / 2 + 10);
  zoomCtx.stroke();
};

const sortBalloons = (balloons) => {
  //Ordena los globos por categoria
  const categorized = balloons.reduce((acc, item) => {
    if (item.colorCategories) {
      item.colorCategories.forEach((color) => {
        if (!acc[color]) {
          acc[color] = [];
        }
        acc[color].push(item);
      });
    }
    return acc;
  }, {});
  Object.keys(categorized).forEach((category) => {
    categorized[category].forEach();
  });
};

const storage = getStorage(app);

const uploadingImage = ({ image, setProgress, collection, name }) => {
  const storageRef = ref(storage, `${collection}/${uuidv4()}_${name}`);
  return new Promise((resolve, reject) => {
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(percentage);
      },
      (error) => {
        console.error("Error al subir la imagen:", error);
        reject(error);
      },
      async () => {
        console.log("Imagen subida correctamente!");
        try {
          const downloadURL = await getDownloadURL(storageRef);
          resolve({ downloadURL, path: storageRef.fullPath });
        } catch (error) {
          console.error("Error obteniendo la URL de descarga:", error);
          reject(error);
        }
      }
    );
  });
};

export const imagesMapToUpload = async ({
  images,
  setProgress,
  collection,
}) => {
  return await Promise.all(
    images.map(async (image) => {
      const { downloadURL, path } = await uploadingImage({
        image,
        setProgress,
        collection,
        name: image.name,
      });

      return {
        imageURL: downloadURL,
        imageName: image.name,
        imagePath: path,
      };
    })
  );
};
