import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Navbar, Container } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import style from "./Footer.module.css";

const Footer = () => {
  return (
    <Navbar className={`${style.footer}`}>
      <Container>
        <Row
          className={`p-0 m-0 align-items-center justify-content-center ${style.container}`}
        >
          <Col xs={11} md={6} xl={4} className={style.firstHalf}>
            <NavLink to="/terms" className={style.links}>
              Terms
            </NavLink>
            <NavLink to="/privacyPolicy" className={style.links}>
              Privacy Policy
            </NavLink>
            <NavLink to="/contactUs" className={style.links}>
              Contact Us
            </NavLink>
          </Col>
          <Col xs={12} md={6} xl={{ offset: 4, span: 4 }}>
            <Row className="align-items-center">
              <Col xs={9} md={8} lg={9} className={style.copyright}>
                <p>
                  © 2024 Balloon Color Match by Paris312. All rights reserved.
                </p>
              </Col>
              <Col xs={3} md={4} lg={3} className={style.media}>
                <a
                  href="https://instagram.com/ballooncolormatch"
                  target="_blank"
                >
                  <FaInstagram
                    color="whitesmoke"
                    className={style.mediaIcons}
                  />
                </a>
                <a
                  href="https://www.facebook.com/BalloonColorMatch"
                  target="_blank"
                >
                  <FaFacebook color="whitesmoke" className={style.mediaIcons} />
                </a>
                <a
                  href="https://www.tiktok.com/@ballooncolormatch"
                  target="_blank"
                >
                  <FaTiktok color="whitesmoke" className={style.mediaIcons} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Footer;
