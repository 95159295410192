import React, { useCallback, useState } from "react";
import { useAppContext } from "../../context";
import { Col, Row } from "react-bootstrap";
import { sizes } from "../../utils/enums";
import { useBalloonsV2 } from "../../utils/data";
import { colorMatchFn } from "../../utils/auxiliarFunctions";
import useFilterByBrand from "../../utils/Hooks/useBrandFilter";
import ModalWindow from "../ModalWindow/ModalWindow";
import ColorCard from "../ColorCards/ColorCard";
import Dnd from "../DragAndDrop/Dnd";
import ViewDetailsRow from "./ViewDetails/ViewDetailsRow";
import FilterByBrand from "../Filter/FilterByBrand";
import style from "./CardsContainer.module.css";

const CardsContainer = ({ sortable, setSelectedColor }) => {
  const { colormatch, setColormatch } = useAppContext();
  const { data: balloonsData, isLoading } = useBalloonsV2();
  const [modal, setModal] = useState({
    show: false,
    color: {},
  });
  const { closerColors, secondaryMatches, ...colorPicked } = modal.color;
  const { brandsSelected, filteredBrands, handleBrandsChange } =
    useFilterByBrand(closerColors);

  let listOfCloserColors;
  if (filteredBrands) {
    listOfCloserColors = [colorPicked, ...filteredBrands];
  }

  const handleColorMatch = useCallback(
    (elementSelected, index) => {
      colorMatchFn(
        balloonsData,
        isLoading,
        colormatch,
        setColormatch,
        elementSelected,
        index
      );
    },
    [balloonsData, isLoading, colormatch, setColormatch]
  );

  const hideModal = useCallback(() => {
    setModal((prevModal) => ({ ...prevModal, show: false }));
  }, []);

  const replaceColors = useCallback(
    (color) => {
      const index = colormatch.findIndex(
        (item) => item.color === colorPicked.color
      );
      handleColorMatch(color.color, { insert: index });
      hideModal();
    },
    [colormatch, colorPicked.color, handleColorMatch, hideModal]
  );

  const addColor = useCallback(
    (color) => {
      handleColorMatch(color.color);
      hideModal();
    },
    [handleColorMatch, hideModal]
  );

  const renderCloserColors = () => {
    if (!colorPicked) return null;
    if (!filteredBrands || filteredBrands.length === 0) {
      return (
        <>
          <ViewDetailsRow
            index={0}
            key={colorPicked.color}
            item={colorPicked}
            sizes={sizes}
            addColor={addColor}
            replaceColors={replaceColors}
            buttons={true}
          />
          <p className={style.noBalloons}>
            No matches found. There are no items available that meet the
            selected filters or color criteria at this time
          </p>
        </>
      );
    }

    return listOfCloserColors.map((item, index) => (
      <ViewDetailsRow
        index={index}
        key={item.color}
        item={item}
        sizes={sizes}
        addColor={addColor}
        replaceColors={replaceColors}
        buttons={true}
      />
    ));
  };

  return (
    <>
      <Row
        id="cardsContainer"
        xs={2}
        md={3}
        lg={4}
        xl={4}
        className="row-gap-4"
      >
        {sortable ? (
          <Dnd data={colormatch} setData={setColormatch}>
            {colormatch.map((color, index) => (
              <ColorCard
                key={index}
                color={color}
                modal={modal}
                setModal={setModal}
                sortable={sortable}
              />
            ))}
          </Dnd>
        ) : (
          colormatch.map((color, index) => (
            <ColorCard
              key={index}
              color={color}
              modal={modal}
              setModal={setModal}
              sortable={sortable}
              setSelectedColor={setSelectedColor}
            />
          ))
        )}
      </Row>
      <ModalWindow
        show={modal.show}
        onHide={hideModal}
        closeButton={true}
        centered={true}
        size="xl"
        fullscreen
        header={
          <Row>
            <Col className="mb-2" xs={12}>
              <p className={style.title}>
                Color swatches are presented from the closest match to the
                least.
              </p>
            </Col>
            <Col xs={12}>
              <FilterByBrand
                brandsSelected={brandsSelected}
                handleChange={handleBrandsChange}
              />
            </Col>
          </Row>
        }
        body={
          <Row>
            <Col className={`p-0 ${style.infoContainer}`}>
              {renderCloserColors()}
              {secondaryMatches && (
                <>
                  <hr />
                  <p className={`${style.title} px-2 mb-2`}>
                    Secondary matches that might complement your palette
                  </p>
                </>
              )}
              {secondaryMatches?.map((item, index) => (
                <ViewDetailsRow
                  key={index}
                  item={item}
                  index={index}
                  sizes={sizes}
                  addColor={addColor}
                  replaceColors={replaceColors}
                  buttons={false}
                />
              ))}
            </Col>
          </Row>
        }
      />
    </>
  );
};

export default CardsContainer;
