import React, { useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row, Spinner } from "react-bootstrap";
import { app } from "../../../../firebase";
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import style from "./AccessoriesForm.module.css";
import { Toaster } from "sonner";
import Alert from "../../../../utils/Alert";
import { useAddAccessories, useUpdateAccessory } from "../../../../utils/data";
import {
  accessoriesCategories,
  colorCategories,
} from "../../../../utils/enums";
import Inputs from "../../../../components/Forms/Inputs/Inputs";
import { useForm, ReusableForm } from "../../../../components/Forms/useForm";
import { v4 as uuidv4 } from "uuid";
import AutoCompleteFilters from "../../../../components/Forms/Inputs/AutoCompleteFilters";

const initialValues = {
  id: "",
  name: "",
  image: null,
  imageURL: "",
  category: "none",
  hexColor: "",
  colorCategories: [],
};

const db = getFirestore(app);
const storage = getStorage(app);

const AccessoriesForm = ({ editItem, setShow }) => {
  const { values, setValues, handleInputChange } = useForm(initialValues);
  const [selected, setSelected] = useState([]);
  const [progress, setProgress] = useState({ totalBytes: 0, complete: 0 });
  const [error, setError] = useState("");
  const [editImage, setEditImage] = useState(true);
  const [loading, setLoading] = useState(false);

  const addAccessory = useAddAccessories();
  const updateAccessory = useUpdateAccessory();

  useEffect(() => {
    if (editItem) {
      let objectArray;
      if (editItem.colorCategories) {
        objectArray = editItem.colorCategories.map((colorCat) => ({
          name: colorCat,
        }));
        setSelected(objectArray);
      }
      editItem.hexColor = editItem.hexColor.replace("#", "");
      setValues(editItem);
      setEditImage(false);
    }
  }, [editItem, setValues]);

  const uploadingImage = (image) => {
    const storageRef = ref(storage, `accessories/${values.name}.jpg`);
    return new Promise((resolve, reject) => {
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_change",
        (snapshot) => {
          setProgress({
            ...snapshot,
            totalBytes: snapshot.totalBytes,
            complete: snapshot.bytesTransferred,
          });
        },
        (error) => {
          console.error("Error al subir la imagen:", error);
          reject(error);
        },
        async () => {
          console.log("Imagen subida correctamente!");
          try {
            const downloadURL = await getDownloadURL(storageRef);
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateSubmit()) {
      return;
    }
    setError("");
    setLoading(true);
    const accessoryRef = { ...values };
    const { image, ...accessoryToSend } = accessoryRef;

    accessoryToSend.hexColor = accessoryToSend.hexColor.toLowerCase();
    accessoryToSend.colorCategories = selected.map((i) => i.name);
    if (!accessoryToSend.hexColor.includes("#")) {
      accessoryToSend.hexColor = "#" + accessoryToSend.hexColor;
    }

    try {
      const q = query(
        collection(db, "accessories"),
        where("name", "==", accessoryToSend.name)
      );
      const querySnapshot = await getDocs(q);
      let docExist = false;

      if (!editItem) {
        accessoryToSend.id = uuidv4();
      }

      if (!querySnapshot.empty) {
        docExist = true;
      }

      if (docExist && !editItem) {
        Alert.error("There is already a accessory with this name");
        setLoading(false);
        return;
      }

      if (editImage) {
        if (values.image === null) {
          setError("You must upload an image");
          return false;
        } else {
          const downloadURL = await uploadingImage(values.image);
          accessoryToSend.imageURL = downloadURL;
        }
      }

      if (editItem) {
        if (docExist && editItem.name === values.name) {
          const originalData = query(
            collection(db, "accessories"),
            where("name", "==", editItem.name)
          );
          const queryOriginalData = await getDocs(originalData);
          const documentIds = [];
          queryOriginalData.forEach((doc) => {
            documentIds.push(doc.id);
          });
          updateAccessory.mutate({
            accessoryId: documentIds[0],
            accessory: accessoryToSend,
          });
        } else if (docExist) {
          Alert.error("There is already a accessory with this name");
          setLoading(false);
          return;
        } else {
          const originalData = query(
            collection(db, "accessories"),
            where("name", "==", editItem.name)
          );
          const queryOriginalData = await getDocs(originalData);
          const documentIds = [];
          queryOriginalData.forEach((doc) => {
            documentIds.push(doc.id);
          });
          updateAccessory.mutate({
            accessoryId: documentIds[0],
            accessory: accessoryToSend,
          });
        }
      } else {
        addAccessory.mutate({
          accessory: accessoryToSend,
        });
      }

      setLoading(false);
      Alert.success("Submitted successfully");
      setTimeout(() => {
        setShow();
      }, 1000);
    } catch (error) {
      Alert.error("There was an error submitting");
      console.log(error);
      setLoading(false);
    }
  };

  const handleSetEditImage = () => {
    setValues({ ...values, image: null });
    setEditImage(true);
  };

  const validateSubmit = () => {
    if (values.name === "") {
      setError("You must insert a name");
      return false;
    } else if (values.category === "none") {
      setError("You must select a category");
      return false;
    } else if (values.hexColor === "") {
      setError("You must insert a hex color");
      return false;
    } else if (values.hexColor.length < 6 || values.hexColor.length > 6) {
      setError("You must insert a valid hex color code");
      return false;
      /* } else if (selected.length === 0) {
      setError("You must select at least one color category");
      return false; */
    } else if (values.image === null) {
      setError("You must upload an image");
      return false;
    } else {
      return true;
    }
  };

  const handleImageChange = (e) => {
    setValues({ ...values, image: e.target.files[0] });
  };

  return (
    <ReusableForm
      className={style.form}
      onSubmit={(e) => handleSubmit(e, values.name)}
    >
      <Row>
        <Col>
          <Inputs.FormControl
            label="Name"
            name="name"
            value={values.name}
            onChange={handleInputChange}
            type="text"
          />
        </Col>
        <Col>
          <Inputs.Select
            label="Category"
            onChange={handleInputChange}
            value={values.category}
            name="category"
            options={accessoriesCategories}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Inputs.FormControl
            label="Hex Color Code"
            icon="#"
            name="hexColor"
            value={values.hexColor}
            onChange={handleInputChange}
            placeholder="F3F3F3"
            type="text"
            decoration={
              <span
                className={style.hexColorSelected}
                style={{ backgroundColor: `#${values.hexColor}` }}
              ></span>
            }
          />
        </Col>
        <Col>
          <AutoCompleteFilters
            optionalTitle="Color categories"
            options={colorCategories}
            selected={selected}
            setSelected={setSelected}
            placeholder="Select a color category"
            renderOption={(option) => (
              <small className={style.optionTitle}>{option.name}</small>
            )}
          />
        </Col>
      </Row>
      {editImage ? (
        <Col>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Accessory Image</Form.Label>
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
        </Col>
      ) : (
        <Col className={style.changeImg}>
          <Button onClick={handleSetEditImage}>Change image</Button>
        </Col>
      )}

      <span className={style.errorTxt}>{error}</span>

      {progress.totalBytes !== 0 && (
        <ProgressBar
          min={0}
          now={progress.complete}
          max={progress.totalBytes}
          striped
          animated
          label={
            progress.complete === progress.totalBytes
              ? "Complete"
              : "Loading..."
          }
        />
      )}
      <Button className={style.submitBtn} type="submit">
        Submit to database
        {loading && (
          <>
            <span> </span>
            <Spinner size="sm" animation="grow" />
          </>
        )}
      </Button>
      <Toaster richColors position="bottom-center" />
    </ReusableForm>
  );
};

export default AccessoriesForm;
