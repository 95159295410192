import React from "react";
import { Row, Col } from "react-bootstrap";
import CanvasWithZoom from "../Canvas/CanvasWithZoom";
import style from "./ColorPicker.module.css";

const ColorPicker = ({
  brightness,
  index,
  onSelect,
  selectedColor,
  setSelectedColor,
}) => {
  return (
    <Row className={style.imageContainer}>
      <Col>
        <CanvasWithZoom
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          brightness={brightness}
          index={index}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
};

export default ColorPicker;
