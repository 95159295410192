import React, { useState, useEffect } from "react";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { Toaster } from "sonner";
import Alert from "../../../../utils/Alert";
import { useDeleteBalloonV2 } from "../../../../utils/data";
import style from "../Balloons.module.css";

const BalloonDetails = ({ item, setShowDetails }) => {
  const [balloon, setBalloon] = useState({});
  const [confirmDelete, setConfirmDelete] = useState();
  const [deleting, setDeleting] = useState();
  const deleteBalloon = useDeleteBalloonV2();

  useEffect(() => {
    if (item) {
      setBalloon(item);
    }
  }, [item]);

  const handleSetConfirmDelete = () => {
    setConfirmDelete(true);
  };

  const handleDeleteBalloon = async () => {
    setDeleting(true);
    const docId = balloon.color;

    try {
      deleteBalloon.mutate(docId);
      Alert.success("Balloon deleted successfully");
      setTimeout(() => {
        setShowDetails();
        setDeleting(false);
      }, 1000);
    } catch (error) {
      console.log(error);
      Alert.error("There was an error submitting");
    }
  };

  return (
    <Row className={`m-0 p-0 gap-4 ${style.ballonsDetailsContainer}`}>
      <Col md={4}>
        <Image src={balloon.imageURL} width={260} />
      </Col>
      <Col className={style.balloonDetails}>
        <Row>
          <Col>
            <div>
              <span className={style.detailTitles}>Manufacturer's Name: </span>
              <span className={style.detailTexts}>{balloon.name}</span>
            </div>
            <div>
              <span className={style.detailTitles}>Display Name: </span>
              <span className={style.detailTexts}>{balloon.displayName}</span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Hex Color: </span>
              <span className={style.detailTexts}>{balloon.color}</span>
              <span
                style={{ backgroundColor: balloon.color }}
                className={style.hexColorCircle}
              ></span>
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Layered: </span>
              <span className={style.detailTexts}>
                {balloon.combined ? "Yes" : "No"}{" "}
              </span>
            </div>
            {balloon.combined ? (
              <div className="mt-2">
                <span className={style.detailTitles}>Layers: </span>
                <ul className={style.detailTexts}>
                  <li>
                    <b>Inside Color:</b> {balloon.insideColor}
                  </li>
                  <li>
                    <b>Outside Color:</b> {balloon.outsideColor}
                  </li>
                </ul>
              </div>
            ) : null}
            <div className="mt-2">
              <span className={style.detailTitles}>Brand: </span>
              {balloon.combined ? (
                <ul className={style.detailTexts}>
                  <li>
                    <b>Inside Brand:</b> {balloon.insideBrand}
                  </li>
                  <li>
                    <b>Outside Brand:</b> {balloon.outsideBrand}
                  </li>
                </ul>
              ) : (
                <span className={style.detailTexts}>{balloon.brand}</span>
              )}
            </div>
            <div className="mt-2">
              <span className={style.detailTitles}>Category: </span>
              <span className={style.detailTexts}>{balloon.category}</span>
            </div>
          </Col>
          <Col>
            <div>
              <span className={style.detailTitles}>Available Sizes:</span>
            </div>
            <ul className={style.detailTexts}>
              {balloon.size5 && <li>5"</li>}
              {balloon.size7 && <li>7"</li>}
              {balloon.size9 && <li>9"</li>}
              {balloon.size10 && <li>10"</li>}
              {balloon.size11 && <li>11"</li>}
              {balloon.size12 && <li>12"</li>}
              {balloon.size13 && <li>13"</li>}
              {balloon.size14 && <li>14"</li>}
              {balloon.size16 && <li>16"</li>}
              {balloon.size17 && <li>17"</li>}
              {balloon.size18 && <li>18"</li>}
              {balloon.size19 && <li>19"</li>}
              {balloon.size23 && <li>23"</li>}
              {balloon.size24 && <li>24"</li>}
              {balloon.size30 && <li>30"</li>}
              {balloon.size31 && <li>31"</li>}
              {balloon.size36 && <li>36"</li>}
              {balloon.size40 && <li>40"</li>}
              {balloon.size69 && <li>69"</li>}
              {balloon.size160 && <li>160</li>}
              {balloon.size260 && <li>260</li>}
              {balloon.size350 && <li>350</li>}
              {balloon.size360 && <li>360</li>}
              {balloon.size6_1 && <li>6" LINK</li>}
              {balloon.size9_1 && <li>9" LINK</li>}
              {balloon.size12_1 && <li>12" LINK</li>}
              {balloon.size13_1 && <li>13" LINK</li>}
              {balloon.size660_1 && <li>660" LINK</li>}
            </ul>
          </Col>
        </Row>
        <Row className={style.deleteBalloonContainer}>
          <Col md={4}>
            <Button size="sm" variant="danger" onClick={handleSetConfirmDelete}>
              <FaTrash /> Delete balloon
            </Button>
          </Col>
          {confirmDelete && (
            <Row
              className={`justify-content-center ${style.confirmDeleteContainer}`}
            >
              <p className={style.deleteAlert}>
                Deleting this balloon is permanent. Click DELETE to confirm or
                close this window to go back.
              </p>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setConfirmDelete(false)}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={3}>
                <Button
                  size="sm"
                  variant="danger"
                  onClick={handleDeleteBalloon}
                >
                  DELETE
                  {deleting && (
                    <>
                      <span> </span>
                      <Spinner size="sm" animation="grow" />
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Col>
      <Toaster richColors position="bottom-center" />
    </Row>
  );
};

export default BalloonDetails;
