import React from "react";
import { Col, Nav, Offcanvas, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import style from "./HeaderBar.module.css";

const HamburguerMenu = ({
  user,
  loading,
  handleChangeToolMode,
  handleLogOut,
  handleNavLinkClick,
}) => {
  return (
    <Offcanvas.Body className={style.offcanvasBody}>
      <Nav justify className="flex-column">
        {loading ? (
          <Col sm={1}>
            <Spinner animation="grow" size="sm" variant="dark" />
          </Col>
        ) : user ? (
          <>
            <NavLink
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile"
            >
              Profile
            </NavLink>
            <NavLink
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/profile/palettes"
            >
              My palettes
            </NavLink>
            <NavLink onClick={handleLogOut} className={`${style.mobileLinks}`}>
              Log out
            </NavLink>
            {user.role === "1" && (
              <NavLink
                onClick={handleNavLinkClick}
                className={`${style.mobileLinks}`}
                to="/dashboard"
              >
                Dashboard
              </NavLink>
            )}
            <Nav.Link className={`${style.mobileLinks}`} onClick={handleChangeToolMode}>
              Create New Palette
            </Nav.Link>
          </>
        ) : (
          <>
            <NavLink
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/createAccount"
            >
              Sign Up
            </NavLink>

            <NavLink
              onClick={handleNavLinkClick}
              className={`${style.mobileLinks}`}
              to="/login"
            >
              Log In
            </NavLink>
          </>
        )}

        <NavLink
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/product"
        >
          Product
        </NavLink>

        <NavLink
          onClick={handleNavLinkClick}
          className={`${style.mobileLinks}`}
          to="/"
        >
          Home
        </NavLink>

        <NavLink
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/terms"
        >
          Terms
        </NavLink>

        <NavLink
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/privacyPolicy"
        >
          Privacy policy
        </NavLink>

        <NavLink
          onClick={handleNavLinkClick}
          className={style.sublinks}
          to="/contactUs"
        >
          Contact Us
        </NavLink>
      </Nav>
    </Offcanvas.Body>
  );
};

export default HamburguerMenu;
