import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import style from "./SideBar.module.css";
import { FaUser, FaHome } from "react-icons/fa";
import { IoBalloon } from "react-icons/io5";
import { BiSolidParty } from "react-icons/bi";
import HamburguerBtn from "../HamburguerBtn/HamburguerBtn";

const SideBar = () => {
  const [show, setShow] = useState(false);

  const handleOffcanvas = () => {
    setShow(!show);
  };

  const getLinkClassName = (isActive) => {
    return isActive
      ? `${style.activeLink} ${show ? style.expandedActiveLink : ""}`
      : `${style.links} ${show ? style.expandedLink : ""}`;
  };

  return (
    <div className={`${style.sidebar} ${show && style.expanded}`}>
      <div
        className={`${style.sidebarTop} ${show && style.sidebarTopExpanded}`}
      >
        <HamburguerBtn clicked={show} handleOffcanvas={handleOffcanvas} />
      </div>
      <NavLink
        to="/dashboard"
        end
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaHome />
        {show && <p className="m-0">Home</p>}
      </NavLink>
      <NavLink
        to="dashboard/balloons"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <IoBalloon />
        {show && <p className="m-0">Balloons</p>}
      </NavLink>
      <NavLink
        to="dashboard/accessories"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <BiSolidParty />
        {show && <p className="m-0">Accessories</p>}
      </NavLink>
      <NavLink
        to="dashboard/users"
        className={({ isActive }) => getLinkClassName(isActive)}
      >
        <FaUser />
        {show && <p className="m-0">Users</p>}
      </NavLink>
    </div>
  );
};

export default SideBar;
