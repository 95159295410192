import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

import style from "../Login/Login.module.css";
import { NavLink } from "react-router-dom";
import { app } from "../../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Toaster } from "sonner";
import Alert from "../../utils/Alert";

const PasswordReset = () => {
  const auth = getAuth(app);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      Alert.success(
        "A password reset email has been sent. Check your mailbox."
      );
      setLoading(false);
    } catch (error) {
      if (error.code === "auth/missing-email") {
        Alert.error("You must insert a valid email.");
      }
      setLoading(false);
    }
  };

  return (
    <Container fluid={true}>
      <Row className={style.rowContainer}>
        <Col className={style.colContainer}>
          <h1 className={style.title}>Forgot password</h1>
          <Card className={style.card}>
            <Card.Body>
              <p className={style.register}>
                Enter your email address below to receive instructions for
                changing your password.
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className={style.formLabels}>
                    Email address
                  </Form.Label>
                  <Form.Control
                    isInvalid={
                      email.length > 0 &&
                      !email.match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                      )
                    }
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={email}
                    onChange={handleSetEmail}
                    className={style.formInputs}
                  />
                  <Form.Control.Feedback type="invalid">
                    You must write a valid email
                  </Form.Control.Feedback>
                </Form.Group>
                <NavLink to="/login" className={style.register}>
                  Go to <span className={style.registerSignupTxt}>Log in.</span>
                </NavLink>
                <div className="mt-2">
                  <Button type="submit" className={style.loginBtn}>
                    Send{" "}
                    {loading && (
                      <Spinner
                        as="span"
                        size="sm"
                        animation="grow"
                        role="status"
                      />
                    )}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <Toaster richColors position="bottom-center" />
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordReset;
