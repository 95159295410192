import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import ModalWindow from "../ModalWindow/ModalWindow";
import { useBalloonsV2, useAccessoriesCategory } from "../../utils/data";
import { useAppContext } from "../../context";
import useAddBtnModalState from "./useAddBtnModal";
import { AddElementModalContent, CustomModalContent } from "./modalManager";
import style from "./Add.module.css";
import { colorMatchFn } from "../../utils/auxiliarFunctions";
import { VscDiffAdded } from "react-icons/vsc";
import Alert from "../../utils/Alert";
import Filters from "../Filter/Filters";
import DropdownBtn from "../Dropdown/DropdownBtn";

const AddButton = () => {
  const { colormatch, setColormatch, defaultModal } = useAppContext();
  const [category, setCategory] = useState("");
  const { data: balloons, isLoading: loadingBalloons } = useBalloonsV2();
  const { data: accessoryCategory, isLoading: loadingAccessories } =
    useAccessoriesCategory(category);
  const { modalState, showModal, closeModal } = useAddBtnModalState();
  const [selected, setSelected] = useState([]);
  const [customImg, setCustomImage] = useState({
    imageURL: null,
    name: "",
  });

  const handleColorMatch = useCallback(
    (elementSelected) => {
      colorMatchFn(
        balloons,
        loadingBalloons,
        colormatch,
        setColormatch,
        elementSelected
      );
    },
    [balloons, loadingBalloons, colormatch, setColormatch]
  );

  const dropdownOptions = useMemo(
    () => [
      { title: "Latex Balloon", action: () => showModal("balloon") },
      {
        title: "Foil Shape",
        action: () => {
          showModal("foilShape");
          setCategory("Foil Shape");
        },
      },
      {
        title: "Foil Number & Letter",
        action: () => {
          showModal("foilNumberLetter");
          setCategory("Foil Number & Letter");
        },
      },
      {
        title: "Confetti",
        action: () => {
          showModal("confetti");
          setCategory("Confetti");
        },
      },
      {
        title: "Tassel",
        action: () => {
          showModal("tassel");
          setCategory("Tassels");
        },
      },
      {
        title: "Backdrop/Panel Color",
        action: () => {
          showModal("backdropPanel");
          setCategory("Backdrop/Panel Color");
        },
      },
      {
        title: "Floral & Greenery",
        action: () => {
          showModal("floralGreenery");
          setCategory("Floral & Greenery");
        },
      },
      { title: "Custom Upload", action: () => showModal("custom") },
    ],
    [showModal]
  );

  const dropdownOptionsRef = useRef(dropdownOptions);
  useEffect(() => {
    dropdownOptionsRef.current = dropdownOptions;
  }, [dropdownOptions]);

  useEffect(() => {
    if (defaultModal) {
      const option = dropdownOptionsRef.current.find(
        (option) => option.title === defaultModal
      );
      if (option) {
        option.action();
      }
    }
  }, [defaultModal]);

  const handleAdd = () => {
    if (modalState.balloon) {
      selected.forEach((item) => handleColorMatch(item.color));
    } else if (modalState.custom) {
      if (customImg.name === "") return Alert.error("Please provide a title.");
      setColormatch((prevState) => [...prevState, customImg]);
    } else {
      const accessoriesSelected = selected.map((item) => ({
        id: item.id,
        name: item.name,
        hexColor: item.hexColor,
        imageURL: item.imageURL,
      }));
      setColormatch((prevState) => [...prevState, ...accessoriesSelected]);
    }
    setSelected([]);
    setCustomImage({
      imageURL: null,
      name: "",
    });
    closeModal();
  };

  const handleDrop = (acceptedFiles) => {
    const imgSrc = URL.createObjectURL(acceptedFiles[0]);
    setCustomImage({ ...customImg, imageURL: imgSrc });
  };

  const renderModal = () => {
    if (modalState.balloon) {
      return (
        <ModalWindow
          className={style.modalFullscreenCustom}
          show={modalState.balloon}
          onHide={closeModal}
          title="Latex Balloon"
          closeButton={true}
          size="xl"
          body={<Filters selected={selected} setSelected={setSelected} />}
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.foilShape) {
      return (
        <ModalWindow
          show={modalState.foilShape}
          onHide={closeModal}
          title="Foil Shape"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Foil Shape"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.foilNumberLetter) {
      return (
        <ModalWindow
          show={modalState.foilNumberLetter}
          onHide={closeModal}
          title="Foil Number & Letter"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Foil Number & Letter"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.confetti) {
      return (
        <ModalWindow
          show={modalState.confetti}
          onHide={closeModal}
          title="Confetti"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Confetti"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.tassel) {
      return (
        <ModalWindow
          show={modalState.tassel}
          onHide={closeModal}
          title="Tassel"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Tassel"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.backdropPanel) {
      return (
        <ModalWindow
          show={modalState.backdropPanel}
          onHide={closeModal}
          title="Backdrop/Panel Color"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Backdrop/Panel Color"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.floralGreenery) {
      return (
        <ModalWindow
          show={modalState.floralGreenery}
          onHide={closeModal}
          title="Floral & Greenery"
          closeButton={true}
          size={"lg"}
          body={
            <AddElementModalContent
              title="Floral & Greenery"
              options={accessoryCategory}
              loading={loadingAccessories}
              selected={selected}
              setSelected={setSelected}
              placeholder={"Search..."}
              renderOption={(option) => (
                <Row className="justify-content-center">
                  <Col xs={10}>
                    <h4 className={style.optionTitle}>{option.name}</h4>
                  </Col>
                  <Col xs={10}>
                    <small className={style.optionData}>
                      Color Categories: {option.colorCategories}
                    </small>
                  </Col>
                </Row>
              )}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else if (modalState.custom) {
      return (
        <ModalWindow
          show={modalState.custom}
          onHide={closeModal}
          title="Custom Upload"
          closeButton={true}
          size={"lg"}
          body={
            <CustomModalContent
              customImg={customImg}
              handleDrop={handleDrop}
              setCustomImage={setCustomImage}
            />
          }
          footer={
            <Button onClick={handleAdd} className={style.addBtn}>
              Add
            </Button>
          }
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Fragment>
      <DropdownBtn
        variant="dark"
        id="dropdown-basic"
        title={
          <>
            <VscDiffAdded /> Add from Library...
          </>
        }
      >
        {dropdownOptions.map((option, index) => (
          <Dropdown.Item onClick={option.action} key={index}>
            {option.title}
          </Dropdown.Item>
        ))}
      </DropdownBtn>
      {renderModal()}
    </Fragment>
  );
};

export default AddButton;
