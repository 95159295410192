import React from "react";
import { Col, Dropdown, Form } from "react-bootstrap";
import DropdownBtn from "../Dropdown/DropdownBtn";
import { sizes } from "../../utils/enums";

const FilterBySize = ({ sizesSelected, handleChange }) => {
  return (
    <Col className="mb-3">
      <DropdownBtn title="Filter by Size" variant="dark">
        {sizes.map((size, index) => {
          const transformedSize = size.toString().replace(/\./g, "_");

          return (
            <Dropdown.Item
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleChange(`size${transformedSize}`);
              }}
            >
              <Form.Check
                type="switch"
                checked={sizesSelected[`size${transformedSize}`]}
                name={`size${size}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(`size${transformedSize}`);
                }}
                onChange={() => {}}
                label={
                  transformedSize.includes("_1")
                    ? `${transformedSize.split("_")[0]}" LINK`
                    : size === 160
                    ? size
                    : size === 260
                    ? size
                    : size === 350
                    ? size
                    : size === 360
                    ? size
                    : `${size}"`
                }
              />
            </Dropdown.Item>
          );
        })}
      </DropdownBtn>
    </Col>
  );
};

export default FilterBySize;
