import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import HeaderBar from "../components/Navbar/HeaderBar";

const AuthRoutes = () => {
  const navigate = useNavigate();
  const userLoggedIn = localStorage.getItem("userLoggedIn");

  useEffect(() => {
    if (userLoggedIn) {
      return navigate("/");
    }
  }, [userLoggedIn, navigate]);

  return <Outlet />;
};

export default AuthRoutes;
